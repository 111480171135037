// PaginatedCDIsLite are used in case of fetching ALL DesignItems in PUBLIC DesignSet with paginationToken which is used in order to paginate DesignItems

export interface PaginatedCDIsLite {
  designItems: ConDesignItemLite[];
  isLastPage: boolean | null;
  pageSize: number;
  totalItems: number | string | null;
}

//  ConDesignItemLite is used to fetch ALL DesignItems faster

export interface ConDesignItemLite {
  id: string;
  description: string;
  pictureUrl: string;
}

//  ConDesignItemWithPicture is used to fetch ONLY ONE ConDesignItem that will be displayed in Detail

export interface ConDesignItemWithPicture {
  designItem: ConDesignItem;
  pictureUrl: string;
  ideaConProjectUrl: string;
}

//  ConDesignItem is containing complete informations of DesignItem

export interface ConDesignItem {
  pictureId: string | null;
  version: string | null;
  name: string;
  ownerId: string | null;
  designCode: string | null;
  conDesignSetId: string;
  conDesignItemId: string;
  designData: DesignData;
  companyName: string | null;
  created: string | null;
  loadTransferType: number | null;
  authorName: string | null;
  activity: boolean;
  priority: boolean;
  imported: boolean;
}

// DesignData are data that are contained in ConDesignItem

export interface DesignData {
  typology: {
    typologyCode: string | null;
    bearingMember: {
      operationId: number | null;
      order: number | null;
      inclination: number | null;
      continuityType: number | null;
      cssMetaData: {
        arcSegmentCount: number | null;
        cssClass: number | null;
        cssSize: {
          width: number | null;
          height: number | null;
        };
        integralityType: number | null;
        label: string | null;
        mainSegmentCount: number | null;
        shape: number | null;
        type: string | null;
        formCode: number | null;
      };
      rigidity: number | null;
    };
    connectedMembers: [
      {
        operationId: number | null;
        typologicalSectorCode: number | null;
        order: number | null;
        lcsRotatiton: number | null;
        continuityType: number | null;
        cssMetaData: {
          arcSegmentCount: number | null;
          cssClass: number | null;
          cssSize: {
            width: number | null;
            height: number | null;
          };
          integralityType: number | null;
          label: string | null;
          mainSegmentCount: number | null;
          shape: number | null;
          type: string | null;
          formCode: number | null;
        };
        rigidity: number | null;
        dirRelatedToBearing: number | null;
      },
    ];
  };
  manufacture: {
    manufacturingType: number | null;
    conParts: [
      {
        stiffness: number | null;
        category: number | null;
      },
    ];
    operationsInfo: {
      endPlate?: { count: number } | null;
      shiftedEndPlate: { count: number } | null;
      stub: { count: number } | null;
      plateToPlate: { count: number } | null;
      splicePlate: { count: number } | null;
      gussetPlate: {
        count: number;
        gussetPlateType: number;
      } | null;
      connectingPlate: {
        count: number;
        connectingPlateType: number;
      } | null;
      finPlate: { count: number } | null;
      cleat: { count: number } | null;
      anchoring: { count: number; anchoringType: number } | null;
      opening: { count: number } | null;
      generalPlate: { count: number; generalPlateType: number } | null;
      stiffener: { count: number } | null;
    };
  };
}

export enum ManufacturingTypes {
  notSet = 0,
  welded = 1,
  bolted = 2,
  cleat = 4,
  anchoring = 8,
  modification = 16,
}

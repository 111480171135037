const AngleIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.95238 13.963L3.95238 1.47619C3.95238 1.2132 3.73918 1 3.47619 1C3.2132 1 3 1.2132 3 1.47619V14.4815V14.5238C3 14.7868 3.2132 15 3.47619 15H12.4815C12.7679 15 13 14.7679 13 14.4815C13 14.1951 12.7679 13.963 12.4815 13.963H3.95238Z"
        fill="#424242"
      />
    </svg>
  );
};

export default AngleIcon;

import { FormattedMessage } from "react-intl";
import { ConnFeatureFilter, MemberModel } from "../../types/CDIFilter";
import TooltipWithArrow from "./TooltipWithArrow";

interface ISuggestionDesign {
  title: string;
  defaultMessage: string;
  picture: string;
  members: MemberModel[];
  featureFilters: ConnFeatureFilter[];
}

interface Props {
  item: ISuggestionDesign;
  onSuggestionDesignClick: (members: MemberModel[], featureFilters: ConnFeatureFilter[]) => void;
}

const SuggestionDesignCard = ({ item, onSuggestionDesignClick }: Props) => {
  return (
    <TooltipWithArrow
      content={<FormattedMessage id={item.title} defaultMessage={item.defaultMessage} />}
      place="bottom"
    >
      <div
        className="suggestion-design-card"
        onClick={() => onSuggestionDesignClick(item.members, item.featureFilters)}
      >
        <img src={item.picture} alt="CDI thumbnail" />
      </div>
    </TooltipWithArrow>
  );
};

export default SuggestionDesignCard;

import BracedFooting from "../images/1.png";
import CHSsplice from "../images/2.png";
import PlatformConnection from "../images/3.png";
import FrameCorner from "../images/4.png";

export const suggestionCDIs = [
    {
        title: "BracedFooting",
        defaultMessage: "Braced footing",
        picture: BracedFooting,
        members: [
            {
                id: 1,
                memberFilter: {
                    continuityType: 0,
                    cssFormCode: "Unknown",
                    direction: 0,
                    pitch: 45,
                }
            },
            {
                id: 2,
                memberFilter: {
                    continuityType: 0,
                    cssFormCode: "Unknown",
                    direction: 180,
                    pitch: 45,
                }
            },
            {
                id: 3,
                memberFilter: {
                    continuityType: 0,
                    cssFormCode: "Unknown",
                    direction: 0,
                    pitch: 90,
                }
            }
        ],
        featureFilters: [
            { feature: "anchoring", clause: 1 },
            { feature: "cleat", clause: 0 },
            { feature: "spliceplate", clause: 0 },
            { feature: "group_endplate", clause: 0 },
            { feature: "group_finplate", clause: 0 },
        ]
    },
    {
        title: "CHSsplice",
        defaultMessage: "CHS splice",
        picture: CHSsplice,
        members: [
            {
                id: 1,
                memberFilter: {
                    continuityType: 0,
                    cssFormCode: "Circle",
                    direction: 0,
                    pitch: 0,
                }
            },
            {
                id: 2,
                memberFilter: {
                    continuityType: 0,
                    cssFormCode: "Circle",
                    direction: 180,
                    pitch: 0,
                }
            }
        ],
        featureFilters: [
            { feature: "anchoring", clause: 0 },
            { feature: "cleat", clause: 0 },
            { feature: "spliceplate", clause: 0 },
            { feature: "group_endplate", clause: 0 },
            { feature: "group_finplate", clause: 0 },
        ]
    },
    {
        title: "PlatformConnection",
        defaultMessage: "Platform connection",
        picture: PlatformConnection,
        members: [
            {
                id: 1,
                memberFilter: {
                    continuityType: 0,
                    cssFormCode: "Unknown",
                    direction: -90,
                    pitch: 0,
                }
            },
            {
                id: 2,
                memberFilter: {
                    continuityType: 0,
                    cssFormCode: "Unknown",
                    direction: 90,
                    pitch: 0,
                }
            },
            {
                id: 3,
                memberFilter: {
                    continuityType: 1,
                    cssFormCode: "I",
                    direction: 0,
                    pitch: 0,
                }
            }
        ],
        featureFilters: [
            { feature: "anchoring", clause: 0 },
            { feature: "cleat", clause: 0 },
            { feature: "spliceplate", clause: 0 },
            { feature: "group_endplate", clause: 0 },
            { feature: "group_finplate", clause: 0 },
        ]
    },
    {
        title: "FrameCorner",
        defaultMessage: "Frame corner",
        picture: FrameCorner,
        members: [
            {
                id: 1,
                memberFilter: {
                    continuityType: 0,
                    cssFormCode: "Unknown",
                    direction: 0,
                    pitch: 30,
                }
            },
            {
                id: 2,
                memberFilter: {
                    continuityType: 0,
                    cssFormCode: "I",
                    direction: 0,
                    pitch: -90,
                }
            },
        ],
        featureFilters: [
            { feature: "anchoring", clause: 2 },
            { feature: "cleat", clause: 0 },
            { feature: "spliceplate", clause: 0 },
            { feature: "group_endplate", clause: 0 },
            { feature: "group_finplate", clause: 0 },
        ]
    },
]

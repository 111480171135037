interface Props {
  bottom?: boolean;
  ribbon?: boolean;
  detail?: boolean;
}

const Loader = ({ bottom, ribbon, detail }: Props) => {
  return (
    <div
      className={
        bottom
          ? "spinner bottom"
          : ribbon
          ? "spinner ribbon"
          : detail
          ? "spinner detail"
          : "spinner"
      }
    >
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
};

export default Loader;

import {
  ConnFeatureFilter,
  CDIFilterState,
  CDIFilterStateWithFeatureFilters,
} from "../../types/CDIFilter";
import {
  getLastDesignItemConnectorFilter,
  getLastDesignItemFeatureFilter,
  getLastDesignItemSearchFilter,
  setLastDesignItemConnectorFilter,
  setLastDesignItemFeatureFilter,
} from "../../services/lastDesignItemSearchFilter";
import { takeLastSearchFilter } from "../../utils/RouteConstants";

export const getLocalStorageDesignItemsSearchFilter = () => {
  const lastSearchFilter = getLastDesignItemSearchFilter();

  if (lastSearchFilter !== null) {
    return {
      members: lastSearchFilter.members,
      searchQuery: lastSearchFilter.searchQuery,
    };
  }
  return {
    members: [],
    searchQuery: undefined,
  };
};

export const getLastFeatureFilterIfRedirectedFromLogin = (searchParams: URLSearchParams) => {
  const isTakenLastFeatureFilter = searchParams.get(takeLastSearchFilter) === "true";

  return isTakenLastFeatureFilter ? getLastDesignItemFeatureFilter() : null;
};

export const getLastConnectorFilterIfRedirectedFromLogin = (searchParams: URLSearchParams) => {
  const isTakenLastConnectorFilter = searchParams.get(takeLastSearchFilter) === "true";

  return isTakenLastConnectorFilter ? getLastDesignItemConnectorFilter() : null;
};

export const getDesignItemSearchFilterFromLocationStateOrLocalStorage = (
  state: CDIFilterState | CDIFilterStateWithFeatureFilters,
) => {
  if (state && !("featureFilters" in state)) {
    return state as CDIFilterState;
  } else if (state && "featureFilters" in state) {
    return state.searchFilter as CDIFilterState;
  } else {
    return getLocalStorageDesignItemsSearchFilter();
  }
};

export const saveLastDesignItemFilter = (
  updatedFilters: ConnFeatureFilter[],
  filterType: "feature" | "connector",
) => {
  if (!updatedFilters || updatedFilters.length === 0) {
    return;
  }

  const filter = [...updatedFilters];

  if (filterType === "feature") {
    setLastDesignItemFeatureFilter({ featureFilter: filter });
  } else if (filterType === "connector") {
    setLastDesignItemConnectorFilter({ connectorFilter: filter });
  } else {
    return; // Invalid filter type, handle error if needed
  }
};

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import LanguageDropdownOption from "./LanguageDropdownOption";
import { selectLanguages, setLanguage } from "../../services/localizationSlice";
import { FormattedMessage } from "react-intl";
import { Language } from "../../types/Languages";

const LanguageDropdownMenu = () => {
  const dispatch = useAppDispatch();
  const languages = useAppSelector(selectLanguages);

  const languageChangeHandler = (language: Language) => {
    dispatch(setLanguage(language));
  };

  return (
    <div className="language-dropdown" data-testid="language-dropdown">
      {languages.map((item: Language, index) => (
        <LanguageDropdownOption onClick={() => languageChangeHandler(item)} key={index}>
          <FormattedMessage id={item.language} defaultMessage={item.language} />
        </LanguageDropdownOption>
      ))}
    </div>
  );
};

export default LanguageDropdownMenu;

const RectangularHollowIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4.5C4.72386 4.5 4.5 4.72386 4.5 5V11C4.5 11.2761 4.72386 11.5 5 11.5H11C11.2761 11.5 11.5 11.2761 11.5 11V5C11.5 4.72386 11.2761 4.5 11 4.5L5 4.5ZM10.5 5.5V10.5H5.5L5.5 5.5L10.5 5.5Z"
        fill="#424242"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.58539 1.72045C1.53148 1.80025 1.5 1.89645 1.5 2V14C1.5 14.2761 1.72386 14.5 2 14.5H14C14.2761 14.5 14.5 14.2761 14.5 14V2C14.5 1.72386 14.2761 1.5 14 1.5H2C1.99137 1.5 1.98279 1.50022 1.97427 1.50065C1.89285 1.50478 1.81658 1.52838 1.75 1.56689C1.68417 1.60497 1.62783 1.65763 1.58539 1.72045ZM13.5 2.5H2.5L2.5 13.5H13.5V2.5Z"
        fill="#424242"
      />
    </svg>
  );
};

export default RectangularHollowIcon;

import { ReactNode, useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { removeToast } from "../../services/toastsSlice";

export interface ToastProps {
  id: string;
  toastIndex: number;
  message: string | ReactNode;
  duration?: number;
  error?: boolean;
}

export interface ToastItem {
  id: string;
  message: string | ReactNode;
  duration: number;
  error?: boolean;
}

const Toast = ({ id, message, duration = 5000, error }: ToastProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(removeToast(id));
    }, duration);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, id, duration]);

  return (
    <div className={`toast ${error ? "toast-error" : ""}`} key={id}>
      {message}
    </div>
  );
};

export default Toast;

import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FormattedMessage } from "react-intl";

import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
  useFetchSeqDataQuery,
  useFetchUserInformationsQuery,
  useFetchUserQuery,
} from "./services/clApiSlice";
import { selectEnvironmentType, setEnvironmentType } from "./services/environmentTypeSlice";
import { setSeqData } from "./services/seqDataSlice";
import { setUserInformations } from "./services/userSlice";

import Header from "./components/layout/Header";
import Loader from "./components/common/Loader";
import Message from "./components/common/Message";
import SearchPage from "./components/pages/SearchPage";
import CDIDetail from "./components/layout/CDIDetail";
import Footer from "./components/layout/Footer";

import "./css/style.css";
import { publicDesignSet } from "./assets/defaultDesignSets/defaultDesignSets";
import Toasts from "./components/common/Toasts";
import CDIsPage from "./components/pages/CDIsPage";
import { ConDesignItemLite } from "./types/ConDesignItem";
import { DesignSet } from "./types/ConDesignSet";

declare global {
  interface Window {
    dataLayer: any;
  }
}

function App() {
  const dispatch = useAppDispatch();
  const environmentType = useAppSelector(selectEnvironmentType);
  const [expiredTokenItem, setExpiredTokenItem] = useState<ConDesignItemLite | null>(null);
  const [designSet] = useState<DesignSet>(publicDesignSet[0]);

  const {
    data: userInformationsData,
    isLoading: isLoadingUserInformations,
    isFetching: isFetchingUserInformations,
    error: userInformationsError,
  } = useFetchUserInformationsQuery();

  const { data: seqFetchedData } = useFetchSeqDataQuery(
    userInformationsData?.isAuthenticated ? false : skipToken,
  );

  const { data: userData } = useFetchUserQuery(
    userInformationsData?.isAuthenticated ? false : skipToken,
  );

  const urlString = window.location.href;

  useEffect(() => {
    if (userInformationsData) {
      dispatch(setUserInformations(userInformationsData));
    }
  }, [dispatch, userInformationsData]);

  useEffect(() => {
    if (seqFetchedData) {
      dispatch(setSeqData(seqFetchedData));
    }
  }, [seqFetchedData, dispatch]);

  useEffect(() => {
    if (urlString.indexOf("staging") > -1) {
      dispatch(setEnvironmentType("staging"));
    } else if (urlString.indexOf("develop") > -1) {
      dispatch(setEnvironmentType("develop"));
    } else if (urlString.indexOf("localhost") > -1) {
      dispatch(setEnvironmentType("local"));
    } else {
      dispatch(setEnvironmentType("production"));
    }
  }, [dispatch, urlString]);

  useEffect(() => {
    if (
      urlString.indexOf("staging") > -1 ||
      urlString.indexOf("develop") > -1 ||
      urlString.indexOf("localhost") > -1
    ) {
      window.dataLayer.push({
        "event": "react_init",
        "environment_type": "staging",
      });
    } else {
      window.dataLayer.push({
        "event": "react_init",
        "environment_type": "production",
      });
    }
  }, [environmentType, urlString]);

  return (
    <Router>
      <div className="App">
        <Header userInformationsData={userInformationsData} user={userData} />

        <Toasts />

        {isFetchingUserInformations || isLoadingUserInformations ? (
          <div style={{ height: "100%" }}>
            <Loader />
          </div>
        ) : (
          <Routes>
            <Route path="/" element={<Outlet />}>
              <Route path="/" element={<SearchPage designSet={designSet} />} />
              <Route
                path="set/:id"
                element={
                  <CDIsPage
                    userIds={userInformationsData?.userIds}
                    isAuthenticated={userInformationsData?.isAuthenticated}
                    isLoadingUserInformations={isLoadingUserInformations}
                    isFetchingUserInformations={isFetchingUserInformations}
                    userInformationsError={userInformationsError}
                    expiredTokenItem={expiredTokenItem}
                    setExpiredTokenModal={setExpiredTokenItem}
                    designSet={designSet}
                  />
                }
              >
                <Route
                  path="item/:itemId"
                  element={
                    <CDIDetail
                      userInformations={userInformationsData}
                      setExpiredTokenItem={setExpiredTokenItem}
                    />
                  }
                />
              </Route>
            </Route>

            <Route
              path="*"
              element={
                <div style={{ marginTop: "7rem" }}>
                  <Message message={<FormattedMessage id="PageNotFound" />} />
                </div>
              }
            />
          </Routes>
        )}
        <Footer />
      </div>
    </Router>
  );
}

export default App;

import { useState, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Selected } from "@ideastatica/scene/build/Types";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";

import { useAppDispatch } from "../../app/hooks";
import CanvasRenderer from "../common/CanvasRenderer";
import { useFetchSceneMutation } from "../../services/clApiSlice";
import RadioButton from "../common/RadioButton";
import Member from "../common/Member";
import {
  ContinuityType,
  CDIFilterState,
  MemberFilter,
  MemberModel,
  ConnFeatureFilter,
} from "../../types/CDIFilter";
import { initialCDIFilterState } from "../../services/defaultCDIFilter";
import {
  getLastDesignItemSearchFilter,
  setLastDesignItemSearchFilter,
} from "../../services/lastDesignItemSearchFilter";
import { takeLastSearchFilter } from "../../utils/RouteConstants";
import { DesignSet } from "../../types/ConDesignSet";
import { ArrowDownOrangeIcon, MinusIcon, PlusIcon } from "../../assets/icons";
import SuggestionDesignCard from "../common/SuggestionDesignCard";
import { suggestionCDIs } from "../../assets/CDIs/suggestionItems";
import ScrollToTopButton from "../common/ScrollToTopButton";
import { addToast } from "../../services/toastsSlice";
import { trackHubspotPageView } from "../../utils/hubspotUtils";

const getLastSearchFilterIfRedirectedFromLogin = (searchParams: URLSearchParams) => {
  const isTakenLastSearchFilter = searchParams.get(takeLastSearchFilter) === "true";

  if (isTakenLastSearchFilter) {
    return getLastDesignItemSearchFilter();
  }
  return null;
};

const saveLastDesignItemSearchFilter = (
  members: MemberModel[],
  emptyInputs: boolean,
  designSet: DesignSet | null,
  selectedMember: MemberModel,
) => {
  if (designSet?.id && !emptyInputs) {
    const searchFilter = {
      members: members,
      searchQuery: {
        designSetId: designSet.id,
        searchQueryString: encodeURIComponent(
          JSON.stringify({ members: members.map((member) => member.memberFilter) }),
        ),
      },
      selectedMember,
    };
    setLastDesignItemSearchFilter(searchFilter);
  }
};

interface SearchPageProps {
  designSet: DesignSet;
}

const SearchPage = ({ designSet }: SearchPageProps) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedSceneMember, setSelectedSceneMember] = useState<Selected | null>(null);
  const [sceneArray, setSceneArray] = useState<MemberFilter[] | null>(null);

  const prevValues = useRef<MemberFilter[] | null>(null);
  const navigate = useNavigate();

  const [members, setMembers] = useState<MemberModel[]>(initialCDIFilterState.members);
  const [selectedMember, setSelectedMember] = useState<MemberModel>(
    initialCDIFilterState.members[0],
  );

  const [fetchScene, { data: sceneData }] = useFetchSceneMutation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const membersRef = useRef(members);

  const numInputs: NodeListOf<HTMLInputElement> = document.querySelectorAll("input[type=number]");
  const emptyInputs = Array.from(numInputs).some((input) => input.value === "");

  useEffect(() => {
    const lastSearchFilter = getLastSearchFilterIfRedirectedFromLogin(searchParams);

    if (lastSearchFilter !== null) {
      setMembers(lastSearchFilter.members);
      setSelectedMember(lastSearchFilter.selectedMember);
    }
  }, [searchParams]);

  useEffect(() => {
    if (members) {
      setSceneArray(members.map((member) => member.memberFilter));
    }
    prevValues.current = members.map((member) => member.memberFilter);
    membersRef.current = members;
  }, [members]);

  useEffect(() => {
    const fetchSceneData = async () => {
      if (sceneArray) {
        await fetchScene(sceneArray);
      }
    };
    fetchSceneData();
  }, [sceneArray, fetchScene]);

  useEffect(() => {
    var inx =
      members.findIndex((item) => {
        return item.id.toString() === selectedMember?.id.toString();
      }) + 1;
    setSelectedSceneMember({
      id: inx.toString(),
      type: { kind: "member" },
    });
  }, [members, selectedMember]);

  const checkExistingMember = (pitch: number) => {
    return members.some(
      (member) =>
        member.memberFilter.continuityType === ContinuityType.ended &&
        member.memberFilter.cssFormCode === selectedMember.memberFilter.cssFormCode &&
        member.memberFilter.direction === selectedMember.memberFilter.direction &&
        member.memberFilter.pitch === pitch,
    );
  };

  useEffect(() => {
    if (errorMessage) {
      const message = <FormattedMessage id={errorMessage} defaultMessage="An error occured" />;
      const duration = 5000;

      dispatch(addToast({ message, duration, error: true }));
    }

    setErrorMessage("");
  }, [errorMessage, dispatch]);

  useEffect(() => {
    saveLastDesignItemSearchFilter(members, emptyInputs, designSet, selectedMember);
  }, [designSet, members, selectedMember, emptyInputs]);

  const addMember = () => {
    let newPitch = selectedMember.memberFilter.pitch + 30;

    while (checkExistingMember(newPitch)) {
      newPitch += 30;
    }

    const newMember = {
      id: members.length > 0 ? Math.max(...members.map((member) => member.id)) + 1 : 1,
      memberFilter: {
        continuityType: ContinuityType.ended,
        cssFormCode: selectedMember.memberFilter.cssFormCode,
        direction: selectedMember.memberFilter.direction,
        pitch: newPitch,
      },
    };

    const newMembers = [...members, newMember];

    saveLastDesignItemSearchFilter(newMembers, emptyInputs, designSet, selectedMember);
    setMembers(newMembers);
    setSelectedMember(newMember);
  };

  const addMemberHandler = () => {
    addMember();
  };

  const removeMember = (memberToRemoveId?: number) => {
    if (members.length <= 1) {
      return;
    }

    const indexToRemove = members.findIndex((i) => i.id === memberToRemoveId);

    const newSelectedMember = indexToRemove === 0 ? members[1] : members[indexToRemove - 1];
    setSelectedMember(newSelectedMember);

    const updatedMembers = members
      .filter((i) => i.id.toString() !== memberToRemoveId?.toString())
      .map((i) => {
        if (Number(i.id) >= Number(memberToRemoveId)) {
          return { ...i, id: i.id - 1 };
        }
        return i;
      });

    saveLastDesignItemSearchFilter(updatedMembers, emptyInputs, designSet, newSelectedMember);
    setMembers(updatedMembers);
  };

  const removeMemberHandler = () => {
    if (members.length > 1) {
      removeMember(selectedMember?.id);
    }
  };

  const updateMember = (id: number, filter: MemberFilter) => {
    const member = members.find((m) => m.id === id);
    if (member) {
      member.memberFilter = { ...member.memberFilter, ...filter };
      saveLastDesignItemSearchFilter(members, emptyInputs, designSet, selectedMember);
      setSelectedMember({ ...member });
      setMembers([...members]);
    }
  };

  const radioChangeHandler = (e: any) => {
    const newSelectedMemberId = Number(e.target.value);
    const defaultMember = members[0];
    const newSelectedMember = {
      ...(members.find((member) => member.id === newSelectedMemberId) ?? defaultMember),
    };
    saveLastDesignItemSearchFilter(members, emptyInputs, designSet, newSelectedMember);
    setSelectedMember(newSelectedMember);
  };

  const onSearch = () => {
    if (designSet?.id && !emptyInputs) {
      const searchFilter = {
        members: members,
        searchQuery: {
          designSetId: designSet.id,
          searchQueryString: encodeURIComponent(
            JSON.stringify({ members: members.map((member) => member.memberFilter) }),
          ),
        },
        selectedMember,
      };

      setLastDesignItemSearchFilter(searchFilter);
      window.dataLayer.push({
        "event": "cl_overview_search_click",
      });
      const redirectUrl = `set/${designSet.id}`;
      trackHubspotPageView(redirectUrl);
      navigate(redirectUrl, {
        state: searchFilter as CDIFilterState,
      });
    } else {
      setErrorMessage("You need to choose design set");
    }
  };

  const onSelectItem = (selected?: Selected) => {
    if (selected !== undefined) {
      const newSelectedMember = membersRef.current[+selected.id - 1];

      saveLastDesignItemSearchFilter(membersRef.current, emptyInputs, designSet, newSelectedMember);
      setSelectedMember(newSelectedMember);
      setSelectedSceneMember(selected);
    } else {
      setSelectedSceneMember(null);
    }
  };

  const onSuggestionDesignClick = (members: MemberModel[], featureFilters: ConnFeatureFilter[]) => {
    setMembers(members);

    if (designSet?.id && !emptyInputs) {
      const searchFilter = {
        members: members,
        searchQuery: {
          designSetId: designSet.id,
          searchQueryString: encodeURIComponent(
            JSON.stringify({ members: members.map((member) => member.memberFilter) }),
          ),
        },
        selectedMember: members[0],
      };

      setLastDesignItemSearchFilter(searchFilter);
      window.dataLayer.push({
        "event": "cl_overview_recommendation_click",
      });
      const redirectUrl = `set/${designSet.id}`;
      trackHubspotPageView(redirectUrl);
      navigate(redirectUrl, {
        state: { searchFilter, featureFilters },
      });
    } else {
      setErrorMessage("You need to choose design set");
    }
  };

  return (
    <div className="search-page">
      <ScrollToTopButton />

      <div className="search-page-title">
        <FormattedMessage
          id="AuthenticConnections"
          defaultMessage="OVER <span>1,000,000</span> AUTHENTIC CONNECTION DESIGNS AT YOUR FINGERTIPS"
          values={{
            span: (chunks) => <span>{chunks}</span>,
          }}
        />
      </div>
      <div className="search-page-content">
        <div className="search-page-content-scene">
          <div className="scene-wrapper">
            <CanvasRenderer
              scene={sceneData}
              selected={selectedSceneMember}
              onSelectItem={onSelectItem}
              showMemberLabels
            />
          </div>
        </div>

        <div className="search-page-content-actions">
          <div className="content-wrapper">
            <div className="member-definition-title">
              <FormattedMessage id="DefineGeometry" defaultMessage="Define your own geometry" />
            </div>
            <div className="member-definition-dropdown">
              <ArrowDownOrangeIcon />
            </div>
            <div className="member-definition-label">
              <FormattedMessage id="Members" defaultMessage="Members" />
              <div className="member-buttons">
                <button className="member-buttons-add" onClick={addMemberHandler}>
                  <PlusIcon />
                </button>
                <button className="member-buttons-remove" onClick={removeMemberHandler}>
                  <MinusIcon />
                </button>
              </div>
            </div>
            <div className="member-definition-options">
              <div className="options-radios">
                {members.map((item, index) => (
                  <label
                    key={index}
                    className={
                      selectedMember?.id === item.id
                        ? "radio-line-checked radio-line"
                        : "radio-line"
                    }
                  >
                    <RadioButton
                      value={item.id}
                      isSelected={selectedMember?.id === item.id}
                      changed={radioChangeHandler}
                    />
                    <FormattedMessage id="MemberInitials" defaultMessage="M" />
                    {item.id}
                  </label>
                ))}
              </div>
            </div>
            {selectedMember !== undefined ? (
              <div className="member-definition-properties">
                <Member selectedMember={selectedMember} onMemberUpdate={updateMember} />

                <button className="search-button" onClick={onSearch}>
                  <FormattedMessage id="Search" defaultMessage="Search" />
                </button>
              </div>
            ) : (
              ""
            )}

            <div className="member-definition-designs">
              <div className="member-definition-designs-title">
                <FormattedMessage
                  id="SearchingSuggestions"
                  defaultMessage="Or see what users are searching for"
                />
              </div>
              <div className="member-definition-designs-items">
                {suggestionCDIs.map((item, index) => (
                  <SuggestionDesignCard
                    key={index}
                    item={item}
                    onSuggestionDesignClick={onSuggestionDesignClick}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default SearchPage;

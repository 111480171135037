import { ReactNode, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";

import English from "../../localization/messages/cl.en-US.json";
import Czech from "../../localization/messages/cl.cs-CZ.json";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../services/localizationSlice";
import { TranslationsRecord } from "../../types/TranslationsRecord";

interface Props {
  children: ReactNode;
}

const LocalizationWrapper = ({ children }: Props) => {
  const language = useAppSelector(selectLanguage);
  const [messages, setMessages] = useState<TranslationsRecord>(English);

  useEffect(() => {
    if (language.cultureName === "en-US") {
      setMessages(English);
    } else {
      setMessages(Czech);
    }
  }, [language.cultureName]);

  const locale = language.cultureName || "en-US";

  return (
    <IntlProvider messages={messages} locale={locale}>
      {children}
    </IntlProvider>
  );
};

export default LocalizationWrapper;

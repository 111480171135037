const setNewLog = (
  timestamp: Date,
  message: string,
  correlationId: string,
  licenseId?: string,
  userAccountId?: string,
  count?: Number,
  designSetId?: string,
  designItemId?: string,
) => {
  return JSON.stringify({
    "@t": timestamp.toISOString(),
    "@mt": message,
    "ServiceName": "ConnectionLibraryFrontend",
    ...(licenseId ? { "LicenseId": licenseId } : {}),
    ...(userAccountId ? { "UserAccountId": userAccountId } : {}),
    ...(count ? { "Count": count } : {}),
    ...(designSetId ? { "ConBrowser_DesignSetId": designSetId } : {}),
    ...(designItemId ? { "ConBrowser_DesignItemId": designItemId } : {}),
    ...(correlationId ? { "CorrelationId": correlationId } : {}),
  });
};

export default setNewLog;

const ViewRightIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1L1 5V15H11L15 11L15 1H5ZM2.25 14L6 11H10V14H2.25ZM13.75 2L10 5H6V2H13.75ZM6 6H10V10H6V6ZM5 10.5194V6H2V12.9194L5 10.5194ZM5 5H2.41421L5 2.41421V5Z"
        fill="#616161"
      />
    </svg>
  );
};

export default ViewRightIcon;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FormattedMessage, useIntl } from "react-intl";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setDesignItem } from "../../services/cdiDetailSlice";
import { useFetchDesignItemQuery, useFetchDetailSceneQuery } from "../../services/clApiSlice";
import { useAddNewLogMutation } from "../../services/seqApiSlice";
import { selectSeqData } from "../../services/seqDataSlice";
import { UserInformations } from "../../types/User";
import setNewLog from "../../functions/setNewLog/setNewLog";
import Message from "../common/Message";
import Loader from "../common/Loader";
import CanvasRenderer from "../common/CanvasRenderer";
import {
  ConDesignItemLite,
  ConDesignItemWithPicture,
  ManufacturingTypes,
} from "../../types/ConDesignItem";
import { capitalize } from "../../functions/capitalize/capitalize";
import { useFetchSceneJsonQuery } from "../../services/sceneSlice";
import { CrossIcon } from "../../assets/icons";
import { DESIGN_CODES } from "../../localization/designCodes";
import { trackHubspotPageView } from "../../utils/hubspotUtils";
import CDIDetailLayout from "./CDIDetailLayout";
import OpenInDesktopButton from "../common/OpenInDesktopButton";

interface Props {
  userInformations: UserInformations | undefined;
  setExpiredTokenItem: (item: ConDesignItemLite) => void;
}

const getManufacturingTypesEnumString = (value: number): string => {
  const separatorOfResult = "&";
  const enumStrings: string[] = [];

  for (const key in ManufacturingTypes) {
    if (typeof ManufacturingTypes[key] === "number") {
      const enumValue = Number(ManufacturingTypes[key]);
      if ((value & enumValue) !== 0) {
        enumStrings.push(capitalize(key));
      }
    }
  }

  return enumStrings.join(` ${separatorOfResult} `);
};

const CDIDetail = ({ userInformations, setExpiredTokenItem }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id, itemId } = useParams();
  const [addNewLog] = useAddNewLogMutation();
  const seqData = useAppSelector(selectSeqData);
  const intl = useIntl();
  const [projectDownloadsExceeded, setProjectDownloadsExceeded] = useState(false);
  const [generateUrlErrored, setGenerateUrlErrored] = useState(false);

  const {
    data: sceneUrlData,
    error: sceneUrlError,
    isLoading: isLoadingSceneUrl,
    isFetching: isFetchingSceneUrl,
  } = useFetchDetailSceneQuery(itemId ? itemId : skipToken);

  const {
    data: sceneJsonData,
    error: sceneJsonError,
    isLoading: isLoadingSceneJson,
    isFetching: isFetchingSceneJson,
    isSuccess: isSuccessSceneJson,
  } = useFetchSceneJsonQuery(
    sceneUrlData && sceneUrlData.webScene3dDataUrl ? sceneUrlData?.webScene3dDataUrl : skipToken,
  );

  const {
    data: designItemData,
    error: designItemError,
    isLoading: isLoadingDesignItem,
    isFetching: isFetchingDesignItem,
    isSuccess: isSuccessDesignItem,
  } = useFetchDesignItemQuery(itemId ? itemId : skipToken);

  useEffect(() => {
    if (designItemData) dispatch(setDesignItem(designItemData));
    setProjectDownloadsExceeded(false);
    setGenerateUrlErrored(false);
  }, [dispatch, designItemData]);

  useEffect(() => {
    const expiredTokenItemJson = localStorage.getItem("detailDesignItem");
    if (
      sceneUrlError &&
      "status" in sceneUrlError &&
      sceneUrlError.status === 401 &&
      expiredTokenItemJson
    ) {
      const conDesignItem = JSON.parse(expiredTokenItemJson) as ConDesignItemLite;
      setExpiredTokenItem(conDesignItem);
      const redirectUrl = `/set/${id}`;
      trackHubspotPageView(redirectUrl);
      navigate(redirectUrl);
    }
  }, [sceneUrlError, navigate, id, setExpiredTokenItem]);

  useEffect(() => {
    if (
      seqData &&
      designItemData &&
      userInformations &&
      userInformations.userIds.licenseId &&
      userInformations.userIds.userId &&
      userInformations.userIds.correlationId
    ) {
      const timestamp = new Date();
      const logObject = {
        "logString": setNewLog(
          timestamp,
          "Design item with ID {ConBrowser_DesignItemId} for the user with ID {UserAccountId} and design set ID {ConBrowser_DesignSetId} was found",
          userInformations.userIds.correlationId,
          userInformations.userIds.licenseId,
          userInformations.userIds.userId,
          undefined,
          designItemData.designItem.conDesignSetId,
          designItemData.designItem.conDesignItemId,
        ),
        "seqEndpoint": seqData.seqEndpoint,
        "seqApiKey": seqData.seqKey,
      };
      addNewLog(logObject);
    }
  }, [addNewLog, seqData, userInformations, designItemData]);

  const version = Number(designItemData?.designItem.version);

  const onCloseDetailPage = () => {
    const redirectUrl = `/set/${id}`;
    trackHubspotPageView(redirectUrl);
    navigate(redirectUrl);
  };

  const designCode = DESIGN_CODES.find((code) => code.id === designItemData?.designItem.designCode);

  const getConnectors = (designData: ConDesignItemWithPicture) => {
    const enumValue = designData.designItem?.designData?.manufacture?.manufacturingType;
    return enumValue === null
      ? intl.formatMessage({ id: "NoConnector", defaultMessage: "No connector" })
      : getManufacturingTypesEnumString(enumValue);
  };

  if (
    isLoadingDesignItem ||
    isFetchingDesignItem ||
    isLoadingSceneJson ||
    isFetchingSceneJson ||
    isLoadingSceneUrl ||
    isFetchingSceneUrl
  ) {
    return (
      <CDIDetailLayout onClose={onCloseDetailPage}>
        <Loader />
      </CDIDetailLayout>
    );
  }

  if (designItemError) {
    return (
      <CDIDetailLayout onClose={onCloseDetailPage}>
        <Message
          detailPage
          message={
            <FormattedMessage
              id="DesignItem_NotFound_TryAgainLater"
              defaultMessage="Design item was not found, try again later"
            />
          }
        />
      </CDIDetailLayout>
    );
  }

  if (sceneJsonError || sceneUrlError) {
    return (
      <CDIDetailLayout onClose={onCloseDetailPage}>
        <Message
          detailPage
          message={
            <FormattedMessage
              id="SceneData_NotFound"
              defaultMessage="Scene data was not found, try again later"
            />
          }
        />
      </CDIDetailLayout>
    );
  }

  if (projectDownloadsExceeded) {
    return (
      <CDIDetailLayout onClose={onCloseDetailPage}>
        <Message
          detailPage
          message={
            <FormattedMessage
              id="DesignItem_Downloads_Exceeded"
              defaultMessage="You have reached your project download limit. Please try again tomorrow."
            />
          }
        />
      </CDIDetailLayout>
    );
  }

  if (generateUrlErrored) {
    return (
      <CDIDetailLayout onClose={onCloseDetailPage}>
        <Message
          detailPage
          message={
            <FormattedMessage
              id="DesignItem_Downloads_Error"
              defaultMessage="Failed to download project from the server. Please try again later."
            />
          }
        />
      </CDIDetailLayout>
    );
  }

  return (
    <div className="CDIDetail">
      <div className="close-icon" onClick={onCloseDetailPage}>
        <CrossIcon />
      </div>

      {isSuccessDesignItem && designItemData && sceneJsonData && isSuccessSceneJson ? (
        <>
          <div className="CDIDetail-scene-wrapper">
            <CanvasRenderer
              key={designItemData.designItem.conDesignItemId}
              scene={sceneJsonData}
              detailPage
            />
          </div>

          <div className="CDIDetail-properties">
            <div className="CDIDetail-properties-top">
              <h2 className="CDIDetail-heading">
                <FormattedMessage id="Properties" defaultMessage="Properties" />
              </h2>
            </div>

            <span className="CDIDetail-description">
              <span className="CDIDetail-description-name">
                {intl.formatMessage({ id: "DesignCode", defaultMessage: "Design code" })}
              </span>
              <span className="CDIDetail-description-value">
                {designItemData.designItem.designCode && designCode
                  ? intl.formatMessage({
                      id: designItemData.designItem.designCode,
                      defaultMessage: designCode.defaultMessage,
                    })
                  : ""}
              </span>
            </span>

            <span className="CDIDetail-description">
              <span className="CDIDetail-description-name">
                {intl.formatMessage({ id: "Connectors", defaultMessage: "Connectors" })}
              </span>
              <div className="CDIDetail-description-value">{getConnectors(designItemData)}</div>
            </span>
            <OpenInDesktopButton
              version={version}
              userInformations={userInformations}
              designItem={designItemData}
              setProjectDownloadsExceeded={setProjectDownloadsExceeded}
              setGenerateUrlErrored={setGenerateUrlErrored}
            />
            <div className="CDIDetail-properties-download">
              <span className="CDIDetail-properties-download-text">
                {intl.formatMessage({
                  id: "DontHaveDesktop",
                  defaultMessage: "Don't have the desktop application installed yet?",
                })}
              </span>
            </div>
            <div className="CDIDetail-properties-download-now">
              <a
                href="https://www.ideastatica.com/product-downloads"
                target="_blank"
                rel="noreferrer"
              >
                {intl.formatMessage({
                  id: "DownloadIdeaNow",
                  defaultMessage: "Download now!",
                })}
              </a>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default CDIDetail;

export const formCodes = [
    {
        value:"Unknown"
    },
    {
        value:"I"
    },
    {
        value:"U"
    },
    {
        value:"L"
    },
    {
       value:"T"
    },
    {
        value:"Rectangle"
    },
    {
        value:"Circle"
    },
    {
        value:"Flat"
    },
    {
        value:"Rod"
    },
]    


    

import { ReactNode } from "react";
import DropdownItem from "./DropdownItem";

interface Props {
  link: string;
  label: ReactNode;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  setIsHovering?: () => void;
}

const DropdownMenu = ({ link, label, onMouseOut, onMouseOver, setIsHovering }: Props) => {
  return (
    <div className="dropdown" onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      <DropdownItem link={link} auth={true} setIsHovering={setIsHovering}>
        {label}
      </DropdownItem>
    </div>
  );
};

export default DropdownMenu;

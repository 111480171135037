const ChannelIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 1.53846C13 1.83585 12.7589 2.07692 12.4615 2.07692H3.95238L3.95238 13.9231H12.4615C12.7589 13.9231 13 14.1642 13 14.4615C13 14.7589 12.7589 15 12.4615 15H3.47619C3.2132 15 3 14.7868 3 14.5238V14.4615V1.53846V1.47619C3 1.2132 3.2132 1 3.47619 1H12.4615C12.7589 1 13 1.24108 13 1.53846Z"
        fill="#424242"
      />
    </svg>
  );
};

export default ChannelIcon;

import { ReactNode } from "react";
import { IconContext } from "react-icons";
import { BiErrorCircle } from "react-icons/bi";

interface Props {
  message: string | ReactNode;
  detailPage?: boolean;
  searchPage?: boolean;
}

const Message = ({ message, detailPage, searchPage }: Props) => {
  const innerMessage = (
    <div className="message-content">
      <IconContext.Provider value={{ color: "#f75b4b" }}>
        <BiErrorCircle size={30} />
      </IconContext.Provider>
      <p className="message-content-text">{message}</p>
    </div>
  );

  if (detailPage) {
    return <div className="message message-detail">{innerMessage}</div>;
  } else if (searchPage) {
    return <div className="message message-search">{innerMessage}</div>;
  } else {
    return <div className="message">{innerMessage}</div>;
  }
};

export default Message;

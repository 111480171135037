import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { DesignSet } from "../types/ConDesignSet";

interface DesignSetSliceState {
  id: string | null;
  designSet: DesignSet | null;
  designSets: DesignSet[];
}

const initialState: DesignSetSliceState = {
  id: null,
  designSet: null,
  designSets: [],
};

export const designSetSlice = createSlice({
  name: "designSet",
  initialState,
  reducers: {
    setDesignSet: (state, action: PayloadAction<DesignSet>) => {
      state.designSet = action.payload;
    },
    clearDesignSet: (state) => {
      state.designSet = null;
    },
    setDesignSets: (state, action: PayloadAction<DesignSet[]>) => {
      state.designSets = action.payload;
    },
    clearDesignSets: (state) => {
      state.designSets = [];
    },
  },
});

export default designSetSlice.reducer;

export const { setDesignSet, clearDesignSet, setDesignSets, clearDesignSets } =
  designSetSlice.actions;

export const selectDesignSet = (state: RootState) => state.designSet.designSet;
export const selectDesignSets = (state: RootState) => state.designSets.designSets;
export const selectState = (state: RootState) => state;

const ArrowDownOrangeIcon = () => (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.146447 0.146447C0.341709 -0.0488155 0.658291 -0.0488155 0.853553 0.146447L5 4.29289L9.14645 0.146447C9.34171 -0.0488155 9.65829 -0.0488155 9.85355 0.146447C10.0488 0.341709 10.0488 0.658291 9.85355 0.853553L5.35355 5.35355C5.15829 5.54882 4.84171 5.54882 4.64645 5.35355L0.146447 0.853553C-0.0488155 0.658291 -0.0488155 0.341709 0.146447 0.146447Z"
      fill="#F36E22"
    />
  </svg>
);

export default ArrowDownOrangeIcon;

import { Scene } from "@ideastatica/scene/build/Types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const sceneSlice = createApi({
  reducerPath: "sceneApi",
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    fetchSceneJson: builder.query<Scene, string>({
      query(sceneUrl) {
        return {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type",
          },
          url: `${sceneUrl}`,
        };
      },
    }),
  }),
});

export const { useFetchSceneJsonQuery } = sceneSlice;

import { useAppSelector } from "../../app/hooks";
import { selectToasts } from "../../services/toastsSlice";
import Toast from "./Toast";

const Toasts = () => {
  const toasts = useAppSelector(selectToasts);

  return (
    <div className="toasts-container">
      {toasts.map((toast, index) => (
        <Toast
          id={toast.id}
          toastIndex={index}
          message={toast.message}
          duration={toast.duration}
          error={toast.error}
        />
      ))}
    </div>
  );
};

export default Toasts;

import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { takeLastSearchFilter } from "../../utils/RouteConstants";

const NoResults = () => {
  return (
    <div className="no-results">
      <p className="no-results-title">
        <FormattedMessage id="NoResultsTitle" defaultMessage="No connection designs" />
      </p>
      <p className="no-results-actions">
        <Link to={`/?${takeLastSearchFilter}=${true}`} className="no-results-actions-link">
          <FormattedMessage id="NoResultsLink" defaultMessage="Edit search parameters" />
        </Link>{" "}
        <FormattedMessage id="NoResultsModifyFilters" defaultMessage="or modify the filter" />
      </p>
    </div>
  );
};

export default NoResults;

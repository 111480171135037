const PlusIcon = () => (
  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 1C6 0.723858 5.77614 0.5 5.5 0.5C5.22386 0.5 5 0.723858 5 1V5.5H0.5C0.223858 5.5 0 5.72386 0 6C0 6.27614 0.223858 6.5 0.5 6.5H5V11C5 11.2761 5.22386 11.5 5.5 11.5C5.77614 11.5 6 11.2761 6 11V6.5H10.5C10.7761 6.5 11 6.27614 11 6C11 5.72386 10.7761 5.5 10.5 5.5H6V1Z"
      fill="#F36E22"
    />
  </svg>
);

export default PlusIcon;

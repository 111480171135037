export const defaultDesignSets = [
    {
    id: "empty",
    name: 'Private design set',
    description:null,
    readOnly: true,
    ownerId: null,
    created: null,
    modified: null,
    type: 0,
  },
  {
    id: "empty",
    name: 'Company design set',
    description:null,
    readOnly: true,
    ownerId: null,
    created: null,
    modified: null,
    type: 2,
  },
]

export const publicDesignSet = [
  {
  id: "public",
  name: 'Public design set',
  description:null,
  readOnly: true,
  ownerId: null,
  created: null,
  modified: null,
  type: 0,
},
]

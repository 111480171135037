import { ConnFeatureFilter, CDIFilterState, MemberModel } from "../types/CDIFilter";

export interface LastSearchFilter extends CDIFilterState {
  selectedMember: MemberModel;
}

export interface LastFeatureFilter {
  featureFilter: ConnFeatureFilter[];
}

export interface LastConnectorFilter {
  connectorFilter: ConnFeatureFilter[];
}

const designItemSearchFilterKey = "designItemSearchFilter";
const designItemFeatureFilterKey = "designItemFeatureFilter";
const designItemConnectorFilterKey = "designItemConnectorFilter";

export const setLastDesignItemSearchFilter = (designItemSearchFilter: LastSearchFilter) => {
  localStorage.setItem(designItemSearchFilterKey, JSON.stringify(designItemSearchFilter));
};

export const setLastDesignItemFeatureFilter = (designItemFeatureFilter: LastFeatureFilter) => {
  localStorage.setItem(designItemFeatureFilterKey, JSON.stringify(designItemFeatureFilter));
};

export const setLastDesignItemConnectorFilter = (
  designItemConnectorFilter: LastConnectorFilter,
) => {
  localStorage.setItem(designItemConnectorFilterKey, JSON.stringify(designItemConnectorFilter));
};

export const getLastDesignItemSearchFilter = (): LastSearchFilter | null => {
  const result = localStorage.getItem(designItemSearchFilterKey);
  if (result !== null) {
    return JSON.parse(result);
  }

  return null;
};

export const getLastDesignItemFeatureFilter = (): LastFeatureFilter | null => {
  const result = localStorage.getItem(designItemFeatureFilterKey);
  return result === null ? null : JSON.parse(result);
};

export const getLastDesignItemConnectorFilter = (): LastConnectorFilter | null => {
  const result = localStorage.getItem(designItemConnectorFilterKey);
  return result === null ? null : JSON.parse(result);
};

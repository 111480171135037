import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { UserInformations } from "../types/User";

export interface UserInformationsSliceState {
  userInformations: UserInformations | null;
}

const initialState: UserInformationsSliceState = {
  userInformations: null,
};

export const userSlice = createSlice({
  name: "openConnection",
  initialState,
  reducers: {
    setUserInformations: (state, action: PayloadAction<UserInformations>) => {
      state.userInformations = action.payload;
    },
    clearUserInformations: (state) => {
      state.userInformations = null;
    },
  },
});

export default userSlice.reducer;

export const { setUserInformations, clearUserInformations } = userSlice.actions;

export const selectUserInformations = (state: RootState) => state.userInformations.userInformations;

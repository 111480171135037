const TypeContinuousIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 1C3.94772 1 3.5 1.44772 3.5 2V14C3.5 14.5523 3.94772 15 4.5 15H6.5C7.05228 15 7.5 14.5523 7.5 14V11H12C12.2761 11 12.5 10.7761 12.5 10.5C12.5 10.2239 12.2761 10 12 10H7.5V9H12C12.2761 9 12.5 8.77614 12.5 8.5C12.5 8.22386 12.2761 8 12 8H7.5V2C7.5 1.44772 7.05228 1 6.5 1H4.5Z"
        fill="#424242"
      />
    </svg>
  );
};

export default TypeContinuousIcon;

import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { ConDesignItemLite } from "../../types/ConDesignItem";
import { trackHubspotPageView } from "../../utils/hubspotUtils";

interface Props {
  conDesignItem: ConDesignItemLite;
  setSelectedItem: (item: ConDesignItemLite) => void;
  isAuthenticated: boolean;
}
const ConDesignItem = ({ conDesignItem, setSelectedItem, isAuthenticated }: Props) => {
  const { id: designSetId, itemId } = useParams();
  const navigate = useNavigate();

  const handleItemNavigation = (item: ConDesignItemLite) => {
    if (isAuthenticated) {
      window.dataLayer.push({
        "event": "cl_open_to_desktop_button_shown",
      });
      localStorage.setItem("detailDesignItem", JSON.stringify(item));
      const redirectUrl = `/set/${designSetId}/item/${item.id}`;
      trackHubspotPageView(redirectUrl)
      navigate(redirectUrl);
    } else {
      window.dataLayer.push({
        "event": "cl_selection_not_logged_click",
      });
      setSelectedItem(item);
    }
  };

  return (
    <div
      className={`ConDesignItem ${itemId === conDesignItem.id ? "active" : ""}`}
      onClick={() => handleItemNavigation(conDesignItem)}
    >
      <img
        src={conDesignItem.pictureUrl}
        className="ConDesignItem--image"
        alt="connection thumbnail"
      />
    </div>
  );
};

export default ConDesignItem;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { ConDesignItemWithPicture, PaginatedCDIsLite } from "../types/ConDesignItem";

interface DesignItemsSliceState {
  items: ConDesignItemWithPicture[];
  itemsLite: PaginatedCDIsLite;
}

interface DesignItemsPayload {
  data: PaginatedCDIsLite;
  isShowMoreClicked: boolean;
}

const initialState: DesignItemsSliceState = {
  items: [],
  itemsLite: {
    designItems: [],
    isLastPage: null,
    pageSize: 40,
    totalItems: null,
  },
};

export const designItemsSlice = createSlice({
  name: "designItems",
  initialState,
  reducers: {
    setDesignItems: (state, action: PayloadAction<ConDesignItemWithPicture[]>) => {
      state.items = state.items.concat(action.payload);
    },
    setDesignItemsLite: (state, action: PayloadAction<DesignItemsPayload>) => {
      const addDesignItems = () => {
        action.payload.data.designItems.forEach((designItem) => {
          if (!state.itemsLite.designItems.some((item) => item.id === designItem.id)) {
            state.itemsLite.designItems.push(designItem);
          }
        });
      };
      if (action.payload.isShowMoreClicked && action.payload.data) {
        state.itemsLite.totalItems = action.payload.data.totalItems;

        if (state.itemsLite.designItems && action.payload.data.designItems) {
          addDesignItems();
        }
      } else if (action.payload.data) {
        state.itemsLite.totalItems = action.payload.data.totalItems;
        state.itemsLite.designItems = [];
        addDesignItems();
      }
    },
    setIsLastPage: (state, action: PayloadAction<boolean | null>) => {
      state.itemsLite.isLastPage = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.itemsLite.pageSize = action.payload;
    },
    clearDesignItems: (state) => {
      state.items = [];
    },
    clearDesignItemsLite: (state) => {
      state.itemsLite.designItems = [];
    },
  },
});

export const {
  setDesignItems,
  setDesignItemsLite,
  setIsLastPage,
  clearDesignItems,
  clearDesignItemsLite,
} = designItemsSlice.actions;

export const selectItems = (state: RootState) => state.items.items;

export const selectItemsLite = (state: RootState) => state.itemsLite.itemsLite;

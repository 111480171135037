const FlatBarIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5.5C1.72386 5.5 1.5 5.72386 1.5 6V10C1.5 10.2761 1.72386 10.5 2 10.5H14C14.2761 10.5 14.5 10.2761 14.5 10V6C14.5 5.72386 14.2761 5.5 14 5.5H2ZM13.5 6.5H2.5L2.5 9.5H13.5V6.5Z"
        fill="#424242"
      />
    </svg>
  );
};

export default FlatBarIcon;

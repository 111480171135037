const ArrowUpWhiteIcon = () => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.85355 5.35355C9.65829 5.54882 9.34171 5.54882 9.14645 5.35355L5 1.20711L0.853554 5.35355C0.658292 5.54882 0.341709 5.54882 0.146447 5.35355C-0.0488153 5.15829 -0.0488153 4.84171 0.146447 4.64645L4.64645 0.146447C4.84171 -0.0488157 5.15829 -0.0488157 5.35355 0.146447L9.85355 4.64645C10.0488 4.84171 10.0488 5.15829 9.85355 5.35355Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowUpWhiteIcon;

import {Scene} from "@ideastatica/scene/build/Types";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {PaginatedCDIsLite, ConDesignItemWithPicture} from "../types/ConDesignItem";
import {MemberFilter} from "../types/CDIFilter";
import {DesignSet} from "../types/ConDesignSet";
import {TranslationsRecord} from "../types/TranslationsRecord";
import {UserInformations} from "../types/User";
import {User} from "../types/User";
import {SceneUrl} from "../types/SceneUrl";
import {SeqData} from "../types/SeqData";

interface FiltersLite {
    paginationFrom: number;
    designSetId: string | null;
    searchQueryString: string;
}

interface FetchIdeaConPublicUrlQueryParams {
    itemId: string;
    loggingPurpose: string;
}

export const clApiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        credentials: "include",
    }),
    tagTypes: ["Post", "Get"],
    endpoints: (builder) => ({
        fetchIdeaConPublicUrl: builder.query<string, FetchIdeaConPublicUrlQueryParams>({
            query(params) {
                return `/Home/GetIdeaConProjectPublicUrl?designItemId=${params.itemId}&&loggingPurpose=${params.loggingPurpose}`;
            },
        }),
        fetchSeqData: builder.query<SeqData, boolean>({
            query() {
                return `/Home/GetSeqData`;
            },
        }),
        fetchDesignSets: builder.query<DesignSet[], boolean>({
            query() {
                return `/Home/GetDesignSets`;
            },
        }),
        fetchDesignItemsLite: builder.query<PaginatedCDIsLite, FiltersLite>({
            query(filters) {
                return {
                    url: `/Home/GetDesignItemsWithPicturesLite${"?designSetId=" + filters.designSetId}${
                        filters.paginationFrom ? "&from=" + filters.paginationFrom : ""
                    }${filters.searchQueryString ? "&searchQueryFilter=" + filters.searchQueryString : ""}`,
                };
            },
        }),
        fetchDesignItemsLiteAnonymous: builder.query<PaginatedCDIsLite, FiltersLite>({
            query(filters) {
                return {
                    url: `/Home/GetDesignItemsWithPicturesLiteAnonymous${
                        filters.searchQueryString ? "?searchQueryFilter=" + filters.searchQueryString : ""
                    }${filters.paginationFrom ? "&from=" + filters.paginationFrom : ""}`,
                };
            },
        }),
        fetchDesignItem: builder.query<ConDesignItemWithPicture, string>({
            query(itemId) {
                return `/Home/GetDesignItemWithPicture?designItemId=${itemId}`;
            },
        }),
        fetchUserInformations: builder.query<UserInformations, void>({
            query() {
                return `/Home/GetUserInformations`;
            },
        }),
        fetchUser: builder.query<User, boolean>({
            query() {
                return `/User/GetUser`;
            },
        }),
        fetchCultureNames: builder.query<string[], void>({
            query() {
                return `/Home/GetCultureNames`;
            },
        }),
        fetchTranslations: builder.query<TranslationsRecord, string>({
            query(cultureName) {
                return `/Home/GetTranslation?cultureName=${cultureName}`;
            },
        }),
        fetchDetailScene: builder.query<SceneUrl, string>({
            query(itemId) {
                return `/Home/GetScene3dUrl/?designItemId=${itemId}`;
            },
        }),
        fetchDetailScenePost: builder.mutation<Scene, string>({
            query(itemId) {
                return {
                    url: `/Home/GetScene`,
                    method: "POST",
                    body: {itemId},
                };
            },
            invalidatesTags: ["Post"],
        }),
        fetchScene: builder.mutation<Scene, MemberFilter[]>({
            query(members) {
                return {
                    url: `/Connection/GetScene`,
                    method: "POST",
                    body: {members},
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Accept": "application/json",
                    },
                };
            },
            invalidatesTags: ["Post"],
        }),
    }),
});

export const {
    useFetchSeqDataQuery,
    useFetchDesignSetsQuery,
    useFetchDesignItemsLiteQuery,
    useFetchDesignItemQuery,
    useFetchDesignItemsLiteAnonymousQuery,
    useFetchUserInformationsQuery,
    useFetchUserQuery,
    useFetchCultureNamesQuery,
    useFetchTranslationsQuery,
    useFetchSceneMutation,
    useFetchDetailSceneQuery,
    useLazyFetchIdeaConPublicUrlQuery,
} = clApiSlice;

interface Props {
  value: number;
  changed: (e: any) => void;
  isSelected: boolean;
}

const RadioButton = ({ value, changed, isSelected }: Props) => {
  return (
    <label className="radio-container">
      <input type="radio" value={value} onChange={changed} checked={isSelected} />
      <span className="checkmark"></span>
    </label>
  );
};

export default RadioButton;

import { ReactNode } from "react";
import { PlacesType, Tooltip } from "react-tooltip";
import { v4 as uuidv4 } from "uuid";
import "react-tooltip/dist/react-tooltip.css";

interface Props {
  content: ReactNode;
  children: ReactNode;
  place: PlacesType;
}

const TooltipWithArrow = ({ content, children, place }: Props) => {
  const identifierForTooltip = `tooltip-${uuidv4()}`;

  return (
    <>
      <span id={identifierForTooltip}>{children}</span>
      <Tooltip anchorSelect={`#${identifierForTooltip}`} place={place}>
        {content}
      </Tooltip>
    </>
  );
};

export default TooltipWithArrow;

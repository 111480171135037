import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface SeqApiArgs {
  logString: string;
  seqEndpoint: string;
  seqApiKey: string;
}

export const seqApiSlice = createApi({
  reducerPath: "seqApi",
  baseQuery: fetchBaseQuery(),
  tagTypes: ["Post"],
  endpoints: (builder) => ({
    addNewLog: builder.mutation<void, SeqApiArgs>({
      query(seqApiArgs) {
        return {
          url: `${seqApiArgs.seqEndpoint}/api/events/raw?clef`,
          method: "POST",
          body: seqApiArgs.logString,
          headers: {
            "Content-type": "application/vnd.serilog.clef",
            "X-Seq-ApiKey": seqApiArgs.seqApiKey,
          },
        };
      },
      invalidatesTags: ["Post"],
    }),
  }),
});

export const { useAddNewLogMutation } = seqApiSlice;

const TypeEndedIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5 1.5C3.5 1.22386 3.72386 1 4 1C4.27614 1 4.5 1.22386 4.5 1.5V14.5C4.5 14.7761 4.27614 15 4 15C3.72386 15 3.5 14.7761 3.5 14.5V1.5Z"
        fill="#424242"
      />
      <path
        d="M7.5 1.5C7.5 1.22386 7.27614 1 7 1C6.72386 1 6.5 1.22386 6.5 1.5V14.5C6.5 14.7761 6.72386 15 7 15C7.27614 15 7.5 14.7761 7.5 14.5V11H11.5C12.0523 11 12.5 10.5523 12.5 10V9C12.5 8.44772 12.0523 8 11.5 8H7.5V1.5Z"
        fill="#424242"
      />
    </svg>
  );
};

export default TypeEndedIcon;

import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
  children: ReactNode;
  link: string;
  id?: string | undefined | null;
  auth?: boolean;
  setIsHovering?: () => void;
}

const DropdownItem = ({ children, link, id, auth, setIsHovering }: Props) => {
  return (
    <>
      {auth ? (
        <a href={link} className="menu-item" onClick={setIsHovering}>
          {children}
        </a>
      ) : (
        <Link to={link} className="menu-item" onClick={setIsHovering}>
          {children}
        </Link>
      )}
    </>
  );
};

export default DropdownItem;

import { useEffect, useState } from "react";
import { Outlet, useLocation, useParams, useSearchParams } from "react-router-dom";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError, skipToken } from "@reduxjs/toolkit/dist/query";
import { FormattedMessage } from "react-intl";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectItemsLite,
  setDesignItemsLite,
  setIsLastPage,
} from "../../services/designItemsSlice";
import {
  useFetchDesignItemsLiteAnonymousQuery,
  useFetchDesignItemsLiteQuery,
} from "../../services/clApiSlice";
import { useAddNewLogMutation } from "../../services/seqApiSlice";
import { selectSeqData } from "../../services/seqDataSlice";
import { initialCDIFilterState } from "../../services/defaultCDIFilter";
import setNewLog from "../../functions/setNewLog/setNewLog";
import { UserIds } from "../../types/User";
import { DesignSet } from "../../types/ConDesignSet";
import ConnectionSummary from "../layout/ConnectionSummary";
import Loader from "../common/Loader";
import SigninModal from "../common/SigninModal";
import ExpiredTokenModal from "../common/ExpiredTokenModal";
import Filters from "../layout/Filters";
import {
  CDIFilterState,
  CDIFilterStateWithFeatureFilters,
  ConnFeatureFilter,
  DesignItemFilter,
  MemberModel,
  SearchQueryModel,
} from "../../types/CDIFilter";
import { ConDesignItemLite } from "../../types/ConDesignItem";
import {
  getDesignItemSearchFilterFromLocationStateOrLocalStorage,
  getLastConnectorFilterIfRedirectedFromLogin,
  getLastFeatureFilterIfRedirectedFromLogin,
} from "../../functions/filterDesginItems/filterDesignItems";
import ConDesignItem from "../common/ConDesignItem";
import NoResults from "../common/NoResults";
import {
  setLastDesignItemSearchFilter,
  setLastDesignItemConnectorFilter,
  setLastDesignItemFeatureFilter,
} from "../../services/lastDesignItemSearchFilter";

interface Props {
  userIds: UserIds | undefined;
  isAuthenticated: boolean | undefined;
  isLoadingUserInformations: boolean;
  isFetchingUserInformations: boolean;
  userInformationsError: FetchBaseQueryError | SerializedError | undefined;
  expiredTokenItem: ConDesignItemLite | null;
  setExpiredTokenModal: (item: ConDesignItemLite | null) => void;
  designSet: DesignSet;
}

const CDIsPage = ({
  userIds,
  isAuthenticated,
  designSet,
  expiredTokenItem,
  setExpiredTokenModal,
}: Props) => {
  const [isLoadingShowMore, setIsLoadingShowMore] = useState<boolean>(false);
  const [paginationFrom, setPaginationFrom] = useState<number>(0);
  const [members, setMembers] = useState<MemberModel[]>(initialCDIFilterState.members);
  const [searchQuery, setSearchQuery] = useState<SearchQueryModel>();
  const [selectedItem, setSelectedItem] = useState<ConDesignItemLite | null>(null);
  const [showSigninModal, setShowSigninModal] = useState<boolean>(false);
  const [connectorFilters, setConnectorFilters] = useState<ConnFeatureFilter[]>([
    { feature: "bolt", clause: 0 },
    { feature: "weld", clause: 0 },
  ]);
  const [featureFilters, setFeatureFilters] = useState<ConnFeatureFilter[]>([
    { feature: "anchoring", clause: 0 },
    { feature: "cleat", clause: 0 },
    { feature: "spliceplate", clause: 0 },
    { feature: "group_endplate", clause: 0 },
    { feature: "group_finplate", clause: 0 },
  ]);
  const [designCodeFilter, setDesignCodeFilter] = useState<string>("all");

  const dispatch = useAppDispatch();
  const [addNewLog] = useAddNewLogMutation();
  const { id, itemId } = useParams();
  const [searchParams] = useSearchParams();

  const location = useLocation();
  const seqData = useAppSelector(selectSeqData);
  const designItemsLite = useAppSelector(selectItemsLite);
  const cdiFilterState = location.state as CDIFilterStateWithFeatureFilters | CDIFilterState;

  useEffect(() => {
    const lastFeatureFilter = getLastFeatureFilterIfRedirectedFromLogin(searchParams);
    const lastConnectorFilter = getLastConnectorFilterIfRedirectedFromLogin(searchParams);

    if (lastFeatureFilter !== null) {
      setFeatureFilters([...lastFeatureFilter.featureFilter]);
    }

    if (lastConnectorFilter !== null) {
      setConnectorFilters([...lastConnectorFilter.connectorFilter]);
    }
  }, [searchParams]);

  useEffect(() => {
    if (cdiFilterState && "featureFilters" in cdiFilterState && cdiFilterState.featureFilters)
      setFeatureFilters(cdiFilterState.featureFilters);
  }, [cdiFilterState]);

  useEffect(() => {
    const { members: retrievedMembers, searchQuery: retrievedSearchQuery } =
      getDesignItemSearchFilterFromLocationStateOrLocalStorage(cdiFilterState);

    const queryParams = new URLSearchParams(location.search);
    // If it's redirect from Viewer, parse the query string and set
    if (queryParams.get("filterFromViewer")) {
      // Parse the query string from viewer
      const filterFromViewer: DesignItemFilter = JSON.parse(queryParams.get("filterFromViewer")!);
      const viewerMembers: MemberModel[] = filterFromViewer.members.map((member, index) => ({
        id: (index + 1), // We are counting members from 1 in CL
        memberFilter: member,
      }));
      setMembers(viewerMembers);
      setLastDesignItemSearchFilter({
        members: [...viewerMembers],
        searchQuery: undefined,
        selectedMember: viewerMembers[0],
      });

      // Parse feature filter to match CL structure
      filterFromViewer.featureFilters.forEach((filter) => {
        const connectorFilter = connectorFilters.find((f) => f.feature === filter.feature);
        if (connectorFilter) {
          connectorFilter.clause = 1;
        }
        const featureFilter = featureFilters.find((f) => f.feature === filter.feature);
        if (featureFilter) {
          featureFilter.clause = 1;
        }
      });

      setConnectorFilters([...connectorFilters]);
      setLastDesignItemConnectorFilter({ connectorFilter: [...connectorFilters] });
      setFeatureFilters([...featureFilters]);
      setLastDesignItemFeatureFilter({ featureFilter: [...featureFilters] });

      // Remove query string from page to not interfere with the app
      window.history.replaceState(null, "", window.location.pathname);
    } else if (retrievedMembers.length > 0) {
      setMembers(retrievedMembers);
    }

    if (retrievedSearchQuery !== undefined) {
      setSearchQuery(retrievedSearchQuery);
    }
    // eslint-disable-next-line
  }, [location, cdiFilterState, setMembers, setFeatureFilters]);

  useEffect(() => {
    if (searchQuery?.designSetId === "" && id)
      window.location.replace(`${window.location.origin}/set/${id}`);
  }, [id, searchQuery?.designSetId]);

  useEffect(() => {
    const timestamp = new Date();
    if (
      seqData &&
      userIds &&
      userIds.licenseId &&
      userIds.userId &&
      userIds.correlationId &&
      designItemsLite.designItems?.length &&
      designSet?.id
    ) {
      const logObject = {
        "logString": setNewLog(
          timestamp,
          "{Count} design items returned for the user with ID {UserAccountId} and design set ID {ConBrowser_DesignSetId}",
          userIds.correlationId,
          userIds.licenseId,
          userIds.userId,
          designItemsLite.designItems.length,
          designSet.id,
        ),
        "seqEndpoint": seqData.seqEndpoint,
        "seqApiKey": seqData.seqKey,
      };
      addNewLog(logObject);
    }
  }, [addNewLog, userIds, seqData, designItemsLite, designSet, designSet?.id]);

  const {
    data: designItemsLiteData,
    error: designItemsLiteError,
    isFetching: isFetchingDesignItemsLite,
  } = useFetchDesignItemsLiteQuery(
    searchQuery?.designSetId && id !== "empty" && id !== "public"
      ? { ...searchQuery, paginationFrom: paginationFrom }
      : skipToken,
  );

  const {
    data: designItemsLiteAnonymousData,
    error: designItemsLiteAnonymousError,
    isFetching: isFetchingDesignItemsAnonymousLite,
  } = useFetchDesignItemsLiteAnonymousQuery(
    searchQuery?.designSetId && id === "public"
      ? { ...searchQuery, paginationFrom: paginationFrom }
      : skipToken,
  );

  useEffect(() => {
    if (designItemsLiteData?.designItems) {
      dispatch(
        setDesignItemsLite({
          data: designItemsLiteData,
          isShowMoreClicked: paginationFrom !== 0 ? true : false,
        }),
      );
      dispatch(setIsLastPage(designItemsLiteData.isLastPage));
    } else if (designItemsLiteAnonymousData?.designItems) {
      dispatch(
        setDesignItemsLite({
          data: designItemsLiteAnonymousData,
          isShowMoreClicked: paginationFrom !== 0 ? true : false,
        }),
      );
      dispatch(setIsLastPage(designItemsLiteAnonymousData.isLastPage));
    }
  }, [dispatch, designItemsLiteData, designItemsLiteAnonymousData, paginationFrom]);

  useEffect(() => {
    if (designSet?.id && members) {
      const searchQueryString = encodeURIComponent(
        JSON.stringify({
          members: members.map((member) => member.memberFilter),
          featureFilters: [...connectorFilters, ...featureFilters],
          designCodeFilter,
        }),
      );

      setSearchQuery({ designSetId: designSet?.id, searchQueryString });
    }
  }, [featureFilters, connectorFilters, designSet?.id, members, designCodeFilter]);

  useEffect(() => {
    if (designItemsLiteAnonymousError || designItemsLiteError) {
      setPaginationFrom(0);
      dispatch(
        setDesignItemsLite({
          data: { designItems: [], pageSize: 0, isLastPage: true, totalItems: "filtering" },
          isShowMoreClicked: false,
        }),
      );
    }
  }, [designItemsLiteAnonymousError, designItemsLiteError, dispatch]);

  useEffect(() => {
    document.body.style.overflow = showSigninModal ? "hidden" : "auto";
  }, [showSigninModal]);

  useEffect(() => {
    if (isFetchingDesignItemsAnonymousLite === false && isFetchingDesignItemsLite === false)
      setIsLoadingShowMore(isFetchingDesignItemsAnonymousLite);
  }, [isFetchingDesignItemsAnonymousLite, isFetchingDesignItemsLite]);

  const resetDesignItems = () => {
    setPaginationFrom(0);
    dispatch(
      setDesignItemsLite({
        data: { designItems: [], pageSize: 0, isLastPage: true, totalItems: "filtering" },
        isShowMoreClicked: false,
      }),
    );
  };

  const handleRefetchData = () => {
    if (isAuthenticated) {
      setPaginationFrom(paginationFrom + designItemsLite.pageSize);
      setIsLoadingShowMore(true);
    } else {
      setShowSigninModal(true);
    }
  };

  const handleSelectedItemSet = (item: ConDesignItemLite) => {
    setSelectedItem(item);
    if (!isAuthenticated) {
      setShowSigninModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowSigninModal(false);
    setSelectedItem(null);
  };

  const handleCloseExpiredTokenModal = () => {
    setExpiredTokenModal(null);
  };

  if (searchQuery?.designSetId === "") {
    return <></>;
  } else {
    return (
      <>
        <ConnectionSummary members={members} />
        <Filters
          featureFilters={featureFilters}
          connectorFilters={connectorFilters}
          setFeatureFilters={setFeatureFilters}
          setConnectorFilters={setConnectorFilters}
          resetDesignItems={resetDesignItems}
          setDesignCodeFilter={setDesignCodeFilter}
        />

        {showSigninModal && <SigninModal item={selectedItem} handleCloseModal={handleCloseModal} />}
        {expiredTokenItem && (
          <ExpiredTokenModal
            item={expiredTokenItem}
            handleCloseModal={handleCloseExpiredTokenModal}
          />
        )}

        {designItemsLite.totalItems ? (
          <div className="total-items">
            {designItemsLite.totalItems} <FormattedMessage id="Results" defaultMessage="results" />
          </div>
        ) : (
          ""
        )}

        <div className="con-design-items-page">
          {(isFetchingDesignItemsAnonymousLite || isFetchingDesignItemsLite) &&
          !isLoadingShowMore ? (
            itemId ? (
              <div className="loader-container loader-container-detail">
                <Loader />
              </div>
            ) : (
              <div className="loader-container">
                <Loader />
              </div>
            )
          ) : (
            <>
              {designItemsLite.totalItems ? (
                <div
                  className={`con-design-items-page__container ${
                    itemId ? "con-design-items-page__container__detail" : ""
                  }`}
                >
                  {designItemsLite.designItems?.map((item, index) => (
                    <ConDesignItem
                      conDesignItem={item}
                      key={index}
                      setSelectedItem={handleSelectedItemSet}
                      isAuthenticated={isAuthenticated as boolean}
                    />
                  ))}
                </div>
              ) : (
                ""
              )}

              {designItemsLite.isLastPage !== null &&
              designItemsLite.totalItems !== 0 &&
              designItemsLite.totalItems !== "filtering" &&
              designItemsLite.isLastPage === false ? (
                <div
                  className={`con-design-items-page__show-more ${
                    itemId ? "con-design-items-page__show-more__detail" : ""
                  }`}
                >
                  {isLoadingShowMore &&
                  (isFetchingDesignItemsAnonymousLite || isFetchingDesignItemsLite) ? (
                    <Loader />
                  ) : (
                    <button className="button button-secondary" onClick={handleRefetchData}>
                      <FormattedMessage id="ShowMore" defaultMessage="Show more" />
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
            </>
          )}

          {(designItemsLite.totalItems === 0 &&
            !isFetchingDesignItemsAnonymousLite &&
            !isFetchingDesignItemsLite) ||
          (designItemsLiteAnonymousError && !isFetchingDesignItemsAnonymousLite) ||
          (designItemsLiteError && !isFetchingDesignItemsLite) ? (
            <NoResults />
          ) : (
            ""
          )}

          <Outlet />
        </div>
      </>
    );
  }
};

export default CDIsPage;

import React, { useState, ChangeEvent, useEffect } from "react";

interface Props {
  value: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  [key: string]: any;
}

const MinusSign = "-";

const NumberInput = ({ value: initialValue, onChange, onBlur, ...rest }: Props) => {
  const [value, setValue] = useState<string>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const numericValue = Number(inputValue);

    if (!isNaN(numericValue) || inputValue === "" || inputValue === MinusSign) {
      setValue(inputValue);
      onChange?.(inputValue === "" ? "" : inputValue);
    }
  };

  const handleBlur = () => {
    const numericValue = Number(value);
    if (value === MinusSign || value === "") {
      setValue("0");
      onBlur?.("0");
    } else if (!isNaN(numericValue)) {
      setValue(value);
      onBlur?.(value);
    }
  };

  return <input type="text" value={value} onChange={handleChange} onBlur={handleBlur} {...rest} />;
};

export default NumberInput;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { Language } from "../types/Languages";
import { TranslationsRecord } from "../types/TranslationsRecord";

interface ILocalizationSlice {
  translations: TranslationsRecord;
  language: Language;
  languages: Language[];
}

const initialState: ILocalizationSlice = {
  translations: {},
  language: {
    language: "English",
    abbreviation: "EN",
    cultureName: "en-US",
  },
  languages: [
    {
      language: "English",
      abbreviation: "EN",
      cultureName: "en-US",
    },
    {
      language: "Czech",
      abbreviation: "CZ",
      cultureName: "cs-CZ",
    },
  ],
};

export const localizationSlice = createSlice({
  name: "localization",
  initialState,
  reducers: {
    setTranslations: (state, action: PayloadAction<TranslationsRecord>) => {
      state.translations = action.payload;
    },
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
    },
    setLanguages: (state, action: PayloadAction<Language[]>) => {
      state.languages = action.payload;
    },
  },
});

export default localizationSlice.reducer;

export const { setTranslations, setLanguage, setLanguages } = localizationSlice.actions;

export const selectTranslations = (state: RootState) => state.translations.translations;
export const selectLanguage = (state: RootState) => state.language.language;
export const selectLanguages = (state: RootState) => state.languages.languages;

import { useState } from "react";
import { BsPerson } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";

import { ReactComponent as Logo } from "../../assets/logo.svg";
import { UserInformations } from "../../types/User";
import { User } from "../../types/User";
import { useAppSelector } from "../../app/hooks";
import LanguageDropdownMenu from "../common/LanguageDropdownMenu";
import DropdownMenu from "../common/DropdownMenu";
import { selectLanguages, selectLanguage } from "../../services/localizationSlice";
import { takeLastSearchFilter } from "../../utils/RouteConstants";
import { getLoginRedirectUri } from "../../utils/redirectUriUtils";
import { IconContext } from "react-icons";
import { AiOutlineDown } from "react-icons/ai";

export interface HeaderProps {
  userInformationsData?: UserInformations;
  user?: User;
}

const Header = ({ userInformationsData, user }: HeaderProps) => {
  const [isHoveringAuth, setIsHoveringAuth] = useState(false);
  const [isHoveringLanguage, setIsHoveringLanguage] = useState(false);
  const language = useAppSelector(selectLanguage);
  const languages = useAppSelector(selectLanguages);
  const location = useLocation();

  const handleMouseOverLanguage = () => {
    setIsHoveringLanguage(true);
  };

  const handleMouseOutLanguage = () => {
    setIsHoveringLanguage(false);
  };

  const handleMouseOverAuth = () => {
    setIsHoveringAuth(true);
  };

  const handleMouseOutAuth = () => {
    setIsHoveringAuth(false);
  };

  const avatarIconClickAnalytics = () => {
    window.dataLayer.push({
      "event": "cl_icon_avatar_click",
    });
  };

  const redirectUri = getLoginRedirectUri(location.pathname);

  return (
    <div className="header">
      <div className="header-container">
        <div className="header-content">
          <a href={`/?${takeLastSearchFilter}=${true}`} className="header-logo">
            <Logo data-testid="svg-logo" />
            <label className="header-logo-label">Connection Library</label>
          </a>
          <div className="header-menu">
            {userInformationsData?.isAuthenticated === true && user ? (
              <>
                <div
                  onMouseOver={handleMouseOverLanguage}
                  onMouseOut={handleMouseOutLanguage}
                  className="nav-item"
                >
                  <div className="nav-item-link" data-testid="language-nav-item">
                    <label className="link-label" data-testid="selected-language">
                      {language.abbreviation}
                    </label>
                    <IconContext.Provider value={{ color: "#f36e22" }}>
                      <AiOutlineDown size={15} />
                    </IconContext.Provider>
                  </div>

                  {languages.length > 1 && isHoveringLanguage ? (
                    <LanguageDropdownMenu data-testid="mocked-language-dropdown" />
                  ) : (
                    ""
                  )}
                </div>

                <div
                  onMouseOver={handleMouseOverAuth}
                  onMouseOut={handleMouseOutAuth}
                  className="nav-item"
                >
                  {user.email ? (
                    <div className="nav-item-link">
                      <IconContext.Provider value={{ color: "#f36e22" }}>
                        <BsPerson />
                      </IconContext.Provider>
                      <label className="link-label">{user.email}</label>
                      <IconContext.Provider value={{ color: "#f36e22" }}>
                        <AiOutlineDown size={15} />
                      </IconContext.Provider>
                    </div>
                  ) : (
                    <IconContext.Provider value={{ color: "#ffffff" }}>
                      <BsPerson />
                    </IconContext.Provider>
                  )}

                  {isHoveringAuth ? (
                    <DropdownMenu
                      link="/account/logout"
                      label={<FormattedMessage id="Logout" defaultMessage="Logout" />}
                      onMouseOver={handleMouseOverAuth}
                      onMouseOut={handleMouseOutAuth}
                      setIsHovering={() => setIsHoveringAuth(false)}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  onMouseOver={handleMouseOverLanguage}
                  onMouseOut={handleMouseOutLanguage}
                  className="nav-item"
                >
                  <div className="nav-item-link">
                    <label className="link-label" data-testid="selected-language">
                      {language.abbreviation}
                    </label>
                    <IconContext.Provider value={{ color: "#f36e22" }}>
                      <AiOutlineDown size={15} />
                    </IconContext.Provider>
                  </div>

                  {languages.length > 1 && isHoveringLanguage ? (
                    <LanguageDropdownMenu data-testid="mocked-language-dropdown" />
                  ) : (
                    ""
                  )}
                </div>

                <div className="nav-item">
                  <a
                    href={redirectUri}
                    onClick={avatarIconClickAnalytics}
                    className="nav-item-link"
                  >
                    <IconContext.Provider value={{ color: "#ffffff" }}>
                      <BsPerson className="nav-item-icon" />
                    </IconContext.Provider>
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

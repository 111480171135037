import { combineReducers, configureStore, PreloadedState } from "@reduxjs/toolkit";
import { cdiDetailSlice } from "../services/cdiDetailSlice";
import { clApiSlice } from "../services/clApiSlice";
import { environmentTypeSlice } from "../services/environmentTypeSlice";
import { userSlice } from "../services/userSlice";
import { designItemsSlice } from "../services/designItemsSlice";
import { designSetSlice } from "../services/designSetSlice";
import { searchQueryFilterSlice } from "../services/searchQueryFilterSlice";
import { seqApiSlice } from "../services/seqApiSlice";
import { seqDataSlice } from "../services/seqDataSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { localizationSlice } from "../services/localizationSlice";
import { sceneSlice } from "../services/sceneSlice";
import { toastsSlice } from "../services/toastsSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["language", "languages", "translations", "searchQuery", "userInformations"],
};

export const rootReducer = combineReducers({
  seqData: seqDataSlice.reducer,
  designSet: designSetSlice.reducer,
  designSets: designSetSlice.reducer,
  item: cdiDetailSlice.reducer,
  environmentType: environmentTypeSlice.reducer,
  userInformations: userSlice.reducer,
  items: designItemsSlice.reducer,
  itemsLite: designItemsSlice.reducer,
  searchQueryFilter: searchQueryFilterSlice.reducer,
  translations: localizationSlice.reducer,
  language: localizationSlice.reducer,
  languages: localizationSlice.reducer,
  toasts: toastsSlice.reducer,
  [clApiSlice.reducerPath]: clApiSlice.reducer,
  [seqApiSlice.reducerPath]: seqApiSlice.reducer,
  [sceneSlice.reducerPath]: sceneSlice.reducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({ serializableCheck: false }).concat(
        clApiSlice.middleware,
        seqApiSlice.middleware,
        sceneSlice.middleware,
      );
    },
    preloadedState,
  });
}

export type RootState = ReturnType<typeof persistedReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

const ViewTopIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 11L11 15H1V5L5 1H15V11ZM2 13.75L5 10V6H2V13.75ZM14 2.25L11 6V10H14V2.25ZM10 10V6H6V10H10ZM5.4806 11H10V14H3.0806L5.4806 11ZM11 11V13.5858L13.5858 11H11Z"
        fill="#424242"
      />
    </svg>
  );
};

export default ViewTopIcon;

const ViewPerspectiveIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 1V11L11 15H1V5L5 1H15ZM2.25 14L6 11H10V14H2.25ZM11 11V13.5858L13.5858 11H11ZM14 10H11V5.48062L14 3.08062V10ZM6 2H13.75L10 5H6V2ZM6 6H10V10H6V6ZM5 2.41421L2.41421 5H5V2.41421ZM5 6H2V12.9194L5 10.5194V6Z"
        fill="#424242"
      />
    </svg>
  );
};

export default ViewPerspectiveIcon;

const ViewFrontIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1H15L15 11L11 15H1V5L5 1ZM10 5L6 5V2H13.75L10 5ZM14 3.08062L11 5.48062L11 10H14L14 3.08062ZM13.5858 11H11V13.5858L13.5858 11ZM5 5V2.41421L2.41421 5H5Z"
        fill="#616161"
      />
    </svg>
  );
};

export default ViewFrontIcon;

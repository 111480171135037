const DownloadArrowDown = () => (
  <svg width="6" height="16" viewBox="0 0 6 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.38028 13.1462C5.18498 12.9511 4.86848 12.951 4.67318 13.1463L3.52697 14.2928V0.5C3.52697 0.2238 3.30317 0 3.02697 0C2.75077 0 2.52697 0.2238 2.52697 0.5V14.2928L1.38057 13.1463C1.18527 12.951 0.868565 12.9511 0.673465 13.1462C0.478165 13.3415 0.478165 13.6581 0.673365 13.8533L2.67336 15.8535C2.76716 15.9473 2.89437 16 3.02697 16C3.15957 16 3.28686 15.9473 3.38056 15.8535L5.38038 13.8533C5.57558 13.658 5.57558 13.3415 5.38028 13.1462Z"
      fill="#F36E22"
    />
  </svg>
);

export default DownloadArrowDown;

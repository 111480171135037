import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface SearchQueryFilterSliceState {
  searchQueryFilter: string | null;
}

const initialState: SearchQueryFilterSliceState = {
  searchQueryFilter: null,
};

export const searchQueryFilterSlice = createSlice({
  name: "searchQueryFilter",
  initialState,
  reducers: {
    setSearchQueryFilter: (state, action: PayloadAction<string>) => {
      state.searchQueryFilter = action.payload;
    },
    clearSearchQueryFilter: (state) => {
      state.searchQueryFilter = null;
    },
  },
});

export default searchQueryFilterSlice.reducer;

export const { setSearchQueryFilter, clearSearchQueryFilter } = searchQueryFilterSlice.actions;

export const selectSearchQueryFilter = (state: RootState) =>
  state.searchQueryFilter.searchQueryFilter;

import { MouseEventHandler, ReactNode, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { ReactComponent as CheckboxBorder } from "../../assets/checkbox-border.svg";
import { ReactComponent as Check } from "../../assets/check.svg";
import { ReactComponent as Remove } from "../../assets/remove.svg";
import { ConnFeatureFilter, FeatureClauses } from "../../types/CDIFilter";

type FilterTypes = "feature" | "connector";
type FeatureTypes = "anchoring" | "group_endplate" | "group_finplate" | "cleat" | "spliceplate";
type ConnectorTypes = "bolt" | "weld";

function isOfType(value: string, type: FilterTypes): value is FeatureTypes | ConnectorTypes {
  if (type === "connector") {
    return ["bolt", "weld"].includes(value);
  } else if (type === "feature") {
    return ["anchoring", "group_endplate", "group_finplate", "cleat", "spliceplate"].includes(
      value,
    );
  }
  return false;
}

interface Props {
  name: string;
  defaultMessage: string;
  icon: ReactNode;
  label: string;
  value: FeatureClauses;
  onChange: (filter: ConnFeatureFilter, filterType: "feature" | "connector") => void;
  handleSetOnlyFilter?: MouseEventHandler<HTMLDivElement>;
  filters?: ConnFeatureFilter[];
}

const FilterItem = ({
  name,
  defaultMessage,
  icon,
  label,
  value,
  onChange,
  handleSetOnlyFilter,
  filters,
}: Props) => {
  const [filter, setFilter] = useState<ConnFeatureFilter>({
    feature: label,
    clause: value,
  });
  const [active, setActive] = useState(false);

  useEffect(() => {
    setFilter({
      feature: label,
      clause: value,
    });

    if (filters) {
      const isActive = filters.every(
        (filter) =>
          (filter.clause === FeatureClauses.must_not && filter.feature !== label) ||
          (filter.feature === label && filter.clause === FeatureClauses.must),
      );
      setActive(isActive);
    }
  }, [value, label, filters]);

  const handleCheckboxChange = () => {
    let newClause: FeatureClauses;

    if (filter.clause === FeatureClauses.None) {
      newClause = FeatureClauses.must;
    } else if (filter.clause === FeatureClauses.must) {
      newClause = FeatureClauses.must_not;
    } else {
      newClause = FeatureClauses.None;
    }

    const newFilter: ConnFeatureFilter = {
      feature: label,
      clause: newClause,
    };

    setFilter(newFilter);

    if (isOfType(label, "connector")) {
      onChange(newFilter, "connector");
    } else if (isOfType(label, "feature")) {
      onChange(newFilter, "feature");
    } else {
      return;
    }
  };

  const renderCheckboxIcon = () => {
    if (filter.clause === FeatureClauses.must) {
      return <Check style={{ position: "absolute" }} />;
    } else if (filter.clause === FeatureClauses.must_not) {
      return <Remove style={{ position: "absolute", color: "black" }} />;
    } else {
      return null;
    }
  };

  return (
    <div className="filter-item">
      <label className="filter-item-icon-with-label">
        <div className="icon-wrapper">{icon}</div>
        <FormattedMessage id={name} defaultMessage={defaultMessage} />
      </label>

      <div
        className={`filter-item-only ${active ? "filter-item-only-active" : ""}`}
        onClick={handleSetOnlyFilter}
      >
        <FormattedMessage id="only" defaultMessage="only" />
      </div>

      <div className="filter-item-checkbox" onClick={handleCheckboxChange}>
        <CheckboxBorder />
        {renderCheckboxIcon()}
      </div>
    </div>
  );
};

export default FilterItem;

import React from "react";
import { CrossIcon } from "../../assets/icons";

interface CDIDetailLayoutProps {
  onClose: () => void;
  children: React.ReactNode;
}

const CDIDetailLayout: React.FC<CDIDetailLayoutProps> = ({ onClose, children }) => {
  return (
    <div className="CDIDetail">
      <div className="close-icon" onClick={onClose}>
        <CrossIcon />
      </div>
      {children}
    </div>
  );
};

export default CDIDetailLayout;

import { AnyTypeIcon, IShapeIcon, ChannelIcon, AngleIcon, TShapeIcon, RectangularHollowIcon, CircularHollowIcon, FlatBarIcon, CircularBarIcon, TypeContinuousIcon, TypeEndedIcon } from "../assets/icons";

import AustraliaFlag from "../assets/flags/C_Flag_of_Australia.svg";
import CanadaFlag from "../assets/flags/C_Flag_of_Canada.svg";
import ChinaFlag from "../assets/flags/C_Flag_of_China.svg";
import EUFlag from "../assets/flags/C_Flag_of_Europe.svg";
import HongKongFlag from "../assets/flags/C_Flag_of_Hong_Kong.svg";
import IndiaFlag from "../assets/flags/C_Flag_of_India.svg";
import USFlag from "../assets/flags/C_Flag_of_US.svg";
import WorldFlag from "../assets/flags/C_Flag_world.svg";

export const getIconComponent = (value: string) => {
    switch (value) {
        case "Unknown":
            return AnyTypeIcon;
        case "I":
            return IShapeIcon;
        case "U":
            return ChannelIcon;
        case "L":
            return AngleIcon;
        case "T":
            return TShapeIcon;
        case "Rectangle":
            return RectangularHollowIcon;
        case "Circle":
            return CircularHollowIcon;
        case "Flat":
            return FlatBarIcon;
        case "Rod":
            return CircularBarIcon;
        case "continuous":
            return TypeContinuousIcon;
        case "ended":
            return TypeEndedIcon;
        default:
            return null;
    }
};

export const getFlagIcon = (value: string) => {
    switch (value) {
        case "all":
            return WorldFlag;
        case "ECEN":
            return EUFlag;
        case "American":
            return USFlag;
        case "Canada":
            return CanadaFlag;
        case "AUS":
            return AustraliaFlag;
        case "CHN":
            return ChinaFlag;
        case "India":
            return IndiaFlag;
        case "HKG":
            return HongKongFlag;
        default:
            return null;
    }
}

export const DESIGN_CODES =  [
    {
        id: 'ECEN', defaultMessage: 'EN'
    },
    {
        id: 'American', defaultMessage: 'AISC'
    },
    {
        id: 'Canada', defaultMessage: 'CSA'
    },
    {
        id: 'AUS', defaultMessage: 'AS'
    },
    {
        id: 'RUS', defaultMessage: 'SP'
    },
    {
        id: 'CHN', defaultMessage: 'GB'
    },
    {
        id: 'India', defaultMessage: 'IS'
    },
    {
        id: 'HKG', defaultMessage: 'HKG'
    }
]
import { useEffect, useRef, useState, CSSProperties } from "react";
import { IconContext } from "react-icons";
import { AiOutlineDown } from "react-icons/ai";
import { getFlagIcon, getIconComponent } from "../../utils/getIconComponent";

export interface DropdownItem {
  value: string;
  label: string;
}

interface Props {
  items: DropdownItem[];
  defaultItem?: DropdownItem;
  onSelect: (selectedItem: DropdownItem) => void;
  customStyle?: CSSProperties;
  isFlagDropdown?: boolean;
}

const CDISearchDropdown = ({
  items,
  defaultItem,
  onSelect,
  customStyle,
  isFlagDropdown,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<DropdownItem | undefined>(defaultItem);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleItemClick = (item: DropdownItem) => {
    setSelectedItem(item);
    onSelect(item);
    setIsOpen(false);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setSelectedItem(defaultItem);
  }, [defaultItem]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div
      className="design-item-search-dropdown  border-grey-400 border-1 "
      style={customStyle}
      ref={dropdownRef}
    >
      <button
        className="design-item-search-dropdown-toggle text-grey-700"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>
          {isFlagDropdown ? (
            <div className="flag-wrapper">
              <img
                src={getFlagIcon(selectedItem?.value!) as string}
                alt={selectedItem?.label + "_FlagSelected"}
              />
            </div>
          ) : (
            ""
          )}
          {selectedItem ? <span>{selectedItem.label}</span> : "Select an option"}
        </div>
        <IconContext.Provider value={{ color: "#000" }}>
          <AiOutlineDown size={13} />
        </IconContext.Provider>
      </button>
      {isOpen && (
        <ul className="design-item-search-dropdown-menu border-1 border-grey-400">
          {items.map((item, index) => {
            let IconComponent: (() => JSX.Element) | null;
            if (!isFlagDropdown) {
              IconComponent = getIconComponent(item.value);
            }

            return (
              <li
                key={index}
                className={`${selectedItem === item ? "selected" : ""} ${
                  item.value === "Unknown" || item.value === "T" || item.value === "Circle"
                    ? "border-bottom-1 border-grey-400"
                    : ""
                } text-grey-700`}
                onClick={() => handleItemClick(item)}
              >
                {!isFlagDropdown && IconComponent! ? <IconComponent /> : ""}
                {isFlagDropdown ? (
                  <div className="flag-wrapper">
                    <img src={getFlagIcon(item.value) as string} alt={item.label + "_Flag"} />
                  </div>
                ) : (
                  ""
                )}
                {item.label}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default CDISearchDropdown;

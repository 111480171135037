import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  onClick: () => void;
}

const LanguageDropdownOption = ({ children, onClick }: Props) => {
  return (
    <div className="language-dropdown-option" onClick={onClick}>
      {children}
    </div>
  );
};

export default LanguageDropdownOption;

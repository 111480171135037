import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { ConDesignItemWithPicture } from "../types/ConDesignItem";

export interface DesignItemWithPictureSliceState {
  item: ConDesignItemWithPicture | null;
}

const initialState: DesignItemWithPictureSliceState = {
  item: null,
};

export const cdiDetailSlice = createSlice({
  name: "detailPage",
  initialState,
  reducers: {
    setDesignItem: (state, action: PayloadAction<ConDesignItemWithPicture>) => {
      state.item = action.payload;
    },
    clearDesignItem: (state) => {
      state.item = null;
    },
  },
});

export default cdiDetailSlice.reducer;

export const { setDesignItem, clearDesignItem } = cdiDetailSlice.actions;

export const selectItem = (state: RootState) => state.item.item;

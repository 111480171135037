const AnyTypeIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.17076 7.71097L1.45221 3.9315V8.2901L8.17076 12.0696V7.71097ZM8.30158 12.7949C8.34713 12.8241 8.40047 12.84 8.45545 12.84H14.8325C14.908 12.84 14.9804 12.81 15.0338 12.7566C15.0872 12.7032 15.1172 12.6308 15.1172 12.5553V7.54478C15.1172 7.52625 15.1154 7.50791 15.1119 7.49C15.1226 7.43584 15.1166 7.37964 15.0944 7.32863C15.0698 7.27208 15.0266 7.22508 14.9717 7.19501L7.68381 3.19546C7.64121 3.17213 7.59317 3.15989 7.54432 3.15991H1.16752H1.16739C1.10442 3.15988 1.04321 3.18022 0.99335 3.21776C0.943491 3.25529 0.907793 3.30789 0.891847 3.36733C0.884601 3.39433 0.881619 3.42206 0.882831 3.44958V8.45522C0.882817 8.50576 0.896259 8.5554 0.921775 8.59903C0.947292 8.64266 0.983964 8.67871 1.02802 8.70347L8.30158 12.7949ZM8.74027 12.2706H14.5478V7.82947H8.74027V12.2706ZM8.5299 7.15946H13.7459L7.46973 3.7154H2.25375L8.5299 7.15946Z"
        fill="#424242"
      />
    </svg>
  );
};

export default AnyTypeIcon;

const TShapeIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 2.12H12.44C12.7493 2.12 13 1.86928 13 1.56C13 1.25072 12.7493 1 12.44 1H3.56C3.25072 1 3 1.25072 3 1.56C3 1.86928 3.25072 2.12 3.56 2.12H7.5L7.5 14.5C7.5 14.7761 7.72386 15 8 15C8.27614 15 8.5 14.7761 8.5 14.5L8.5 2.12Z"
        fill="#424242"
      />
    </svg>
  );
};

export default TShapeIcon;

import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { MemberFilter, MemberModel } from "../../types/CDIFilter";
import { useFetchSceneMutation } from "../../services/clApiSlice";
import { takeLastSearchFilter } from "../../utils/RouteConstants";
import CanvasRenderer from "../common/CanvasRenderer";
import Loader from "../common/Loader";
import TooltipWithArrow from "../common/TooltipWithArrow";
import { trackHubspotPageView } from "../../utils/hubspotUtils";

interface Props {
  members?: MemberModel[];
}

const ConnectionSummary = ({ members }: Props) => {
  const navigate = useNavigate();
  const [sceneArray, setSceneArray] = useState<MemberFilter[] | null>(null);

  const [fetchScene, { data: sceneData }] = useFetchSceneMutation();

  useEffect(() => {
    if (members) {
      setSceneArray(members.map((member) => member.memberFilter));
    }
  }, [members]);

  useEffect(() => {
    const fetchSceneData = async () => {
      if (sceneArray) {
        await fetchScene(sceneArray);
      }
    };
    fetchSceneData();
  }, [sceneArray, fetchScene]);

  const editSearchParametersOnClick = () => {
    window.dataLayer.push({
      "event": "cl_edit_search_parameters_click",
    });
    const redirectUrl = `/?${takeLastSearchFilter}=${true}`;
    trackHubspotPageView(redirectUrl);
    navigate(redirectUrl);
  };

  return (
    <div className="connection-summary">
      {sceneData && members !== undefined ? (
        <CanvasRenderer scene={sceneData} />
      ) : (
        <Loader ribbon />
      )}
      <div className="connection-summary-wrapper">
        <div className="connection-summary-content">
          <div className="content-inner">
            {members?.map((member, index) => (
              <div className="connection-summary-member" key={index}>
                <div className="member-item member-item-name">
                  <div className="member-item-value">
                    <b>M{member.id}</b>
                  </div>
                </div>
                <TooltipWithArrow
                  content={<FormattedMessage id="CrossSection" defaultMessage="Cross section" />}
                  place="left"
                >
                  <div className="member-item">
                    <div className="member-item-value">
                      {member.memberFilter.cssFormCode ? (
                        <FormattedMessage id={member.memberFilter.cssFormCode} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </TooltipWithArrow>

                <TooltipWithArrow
                  content={
                    <FormattedMessage id="GeometricalType" defaultMessage="Geometrical type" />
                  }
                  place="left"
                >
                  <div className="member-item">
                    <div className="member-item-value">
                      {member.memberFilter.continuityType === 0 ? (
                        <FormattedMessage id="Ended" defaultMessage="Ended" />
                      ) : (
                        <FormattedMessage id="Continuous" defaultMessage="Continuous" />
                      )}
                    </div>
                  </div>
                </TooltipWithArrow>

                <TooltipWithArrow
                  content={
                    <FormattedMessage
                      id="HorizontalRotation"
                      defaultMessage="Horizontal rotation"
                    />
                  }
                  place="left"
                >
                  <div className="member-item">
                    <div className="member-item-value">{member.memberFilter.direction}</div>
                  </div>
                </TooltipWithArrow>

                <TooltipWithArrow
                  content={<FormattedMessage id="VerticalTilt" defaultMessage="Vertical tilt" />}
                  place="left"
                >
                  <div className="member-item">
                    <div className="member-item-value">{member.memberFilter.pitch}</div>
                  </div>
                </TooltipWithArrow>
              </div>
            ))}
          </div>
          <button
            className="button button-secondary button-medium connection-summary-button"
            onClick={editSearchParametersOnClick}
          >
            <FormattedMessage id="EditParameters" defaultMessage="Edit search parameters" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConnectionSummary;

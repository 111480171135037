import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../services/localizationSlice";

const Footer = () => {
  const language = useAppSelector(selectLanguage);

  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-copyright">&#169; IDEA StatiCa 2009-{new Date().getFullYear()}</div>
        <div className="footer-links-wrapper">
          <a
            href={`https://www.surveymonkey.com/r/connection-library${
              language.abbreviation === "CZ" ? "?lang=cs" : ""
            }`}
            className="footer-link"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="GiveUsFeedback" defaultMessage="Give us feedback" />
          </a>
          <a
            href="https://www.ideastatica.com/contact"
            className="footer-link"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="Help" defaultMessage="Help" />
          </a>
        </div>
      </div>
      <script
        id="CookieDeclaration"
        data-culture={language.abbreviation.toLowerCase()}
        src="https://consent.cookiebot.com/8e2e4e84-0ab8-41e8-8d6b-371f7587abbc/cd.js"
        type="text/javascript"
        async
      ></script>
    </div>
  );
};

export default Footer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { SeqData } from "../types/SeqData";

export interface SeqDataSliceState {
  seqData: SeqData | null;
}

const initialState: SeqDataSliceState = {
  seqData: null,
};

export const seqDataSlice = createSlice({
  name: "seqData",
  initialState,
  reducers: {
    setSeqData: (state, action: PayloadAction<SeqData>) => {
      state.seqData = action.payload;
    },
    clearSeqData: (state) => {
      state.seqData = null;
    },
  },
});

export default seqDataSlice.reducer;

export const { setSeqData, clearSeqData } = seqDataSlice.actions;

export const selectSeqData = (state: RootState) => state.seqData.seqData;

import { CDIFilterState } from "../types/CDIFilter";

export const initialCDIFilterState: CDIFilterState = {
  members: [
    {
      id: 1,
      memberFilter: {
        continuityType: 1,
        pitch: 90,
        direction: 0,
        cssFormCode: "Unknown",
      },
    },
    {
      id: 2,
      memberFilter: {
        continuityType: 0,
        pitch: 0,
        direction: 0,
        cssFormCode: "I",
      },
    },
    {
      id: 3,
      memberFilter: {
        continuityType: 0,
        pitch: 0,
        direction: -90,
        cssFormCode: "I",
      },
    },
  ],
  searchQuery: {
    designSetId: "",
    searchQueryString: "",
  },
};

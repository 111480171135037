import React from "react";
import { ContinuityType, MemberFilter, MemberModel } from "../../types/CDIFilter";
import { FormattedMessage, useIntl } from "react-intl";
import TooltipWithArrow from "./TooltipWithArrow";
import CDISearchDropdown, { DropdownItem } from "./CDISearchDropdown";
import NumberInput from "./CDISearchNumberInput";
import { formCodes } from "../../types/formCodes";

interface Props {
  selectedMember: MemberModel;
  onMemberUpdate?: (id: number, filter: MemberFilter) => void;
}

const Member = ({ selectedMember, onMemberUpdate }: Props) => {
  const intl = useIntl();

  const handleCssFormCodeChange = (selectedItem: DropdownItem) => {
    onMemberUpdate?.(selectedMember.id, {
      ...selectedMember.memberFilter,
      cssFormCode: selectedItem.value,
    });
  };

  const handleContinuityTypeChange = (selectedItem: DropdownItem) => {
    onMemberUpdate?.(selectedMember.id, {
      ...selectedMember.memberFilter,
      continuityType: ContinuityType[selectedItem.value as keyof typeof ContinuityType],
    });
  };

  const handlePitchChange = (value: string) => {
    onMemberUpdate?.(selectedMember.id, {
      ...selectedMember.memberFilter,
      pitch: Number(value),
    });
  };

  const handleDirectionChange = (value: string) => {
    onMemberUpdate?.(selectedMember.id, {
      ...selectedMember.memberFilter,
      direction: Number(value),
    });
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const inputs = document.querySelectorAll<HTMLInputElement>(".member-properties-item-input");
      const nextInput = inputs[Array.from(inputs).indexOf(e.currentTarget) + 1];
      if (nextInput) {
        nextInput.focus();
      } else {
        inputs[0].focus();
      }
    }
  };

  const continuityTypeOptions = [
    { label: intl.formatMessage({ id: "Ended", defaultMessage: "Ended" }), value: "ended" },
    {
      label: intl.formatMessage({ id: "Continuous", defaultMessage: "Continuous" }),
      value: "continuous",
    },
  ];

  const cssFormCodeOptions = formCodes.map((x) => {
    return {
      value: x.value,
      label: intl.formatMessage({ id: x.value, defaultMessage: x.value }),
    };
  });

  return (
    <div className="member-definition">
      <div className="member-properties">
        <div className="member-properties-item">
          <div className="member-properties-item-label">
            <TooltipWithArrow
              content={
                <FormattedMessage
                  id="CrossSectionTooltip"
                  defaultMessage="Select cross-section from our database"
                />
              }
              place="left"
            >
              <FormattedMessage id="CrossSection" defaultMessage="Cross section" />
            </TooltipWithArrow>
          </div>
          <CDISearchDropdown
            defaultItem={cssFormCodeOptions.find(
              (x) => x.value === selectedMember.memberFilter.cssFormCode,
            )}
            items={cssFormCodeOptions}
            onSelect={handleCssFormCodeChange}
          />
        </div>

        <div className="member-properties-item">
          <div className="member-properties-item-label">
            <TooltipWithArrow
              content={
                <FormattedMessage
                  id="GeometricalTypeTooltip"
                  defaultMessage="The position of the member in the joint"
                />
              }
              place="left"
            >
              <FormattedMessage id="GeometricalType" defaultMessage="Geometrical type" />
            </TooltipWithArrow>
          </div>
          <CDISearchDropdown
            defaultItem={continuityTypeOptions.find(
              (x) => x.value === ContinuityType[selectedMember.memberFilter.continuityType],
            )}
            items={continuityTypeOptions}
            onSelect={handleContinuityTypeChange}
          />
        </div>
        <div className="member-properties-item">
          <div className="member-properties-item-label">
            <TooltipWithArrow
              content={
                <FormattedMessage
                  id="HorizontalRotationTooltip"
                  defaultMessage="Member rotation around vertical Z axis (+/- 180 degrees)"
                />
              }
              place="left"
            >
              <FormattedMessage id="HorizontalRotation" defaultMessage="Horizontal rotation" />
            </TooltipWithArrow>
          </div>
          <NumberInput
            name="direction"
            className="member-properties-item-input border-1 border-grey-400 text-grey-700"
            value={selectedMember.memberFilter.direction.toString()}
            onBlur={handleDirectionChange}
            onKeyDown={handleInputKeyDown}
          />
        </div>

        <div className="member-properties-item">
          <div className="member-properties-item-label">
            <TooltipWithArrow
              content={
                <FormattedMessage
                  id="VerticalTiltTooltip"
                  defaultMessage="Member inclination from horizontal plane (+/- 90 degrees)"
                />
              }
              place="left"
            >
              <FormattedMessage id="VerticalTilt" defaultMessage="Vertical tilt" />
            </TooltipWithArrow>
          </div>
          <NumberInput
            name="pitch"
            className="member-properties-item-input border-1 border-grey-400 text-grey-700"
            value={selectedMember.memberFilter.pitch.toString()}
            onBlur={handlePitchChange}
            onKeyDown={handleInputKeyDown}
          />
        </div>
      </div>
    </div>
  );
};

export default Member;

const IShapeIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 1.50993C13 1.79156 12.7717 2.01987 12.4901 2.01987H8.5L8.5 13.9801H12.4901C12.7717 13.9801 13 14.2084 13 14.4901C13 14.7717 12.7717 15 12.4901 15H3.50993C3.2283 15 3 14.7717 3 14.4901C3 14.2084 3.22831 13.9801 3.50993 13.9801H7.5L7.5 2.01987H3.50993C3.22831 2.01987 3 1.79156 3 1.50993C3 1.22831 3.2283 1 3.50993 1H12.4901C12.7717 1 13 1.22831 13 1.50993Z"
        fill="#424242"
      />
    </svg>
  );
};

export default IShapeIcon;

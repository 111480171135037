import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToastItem } from "../components/common/Toast";
import { RootState } from "../app/store";
import { v4 as uuidv4 } from "uuid";
import { ReactNode } from "react";

interface AddToast {
  message: string | ReactNode;
  duration: number;
  error?: boolean;
}

interface ToastsSliceState {
  toasts: ToastItem[];
}

const initialState: ToastsSliceState = {
  toasts: [],
};

export const toastsSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<AddToast>) => {
      const { message, duration, error } = action.payload;
      const id = uuidv4();
      state.toasts.push({ id, message, duration, error });
    },
    removeToast: (state, action: PayloadAction<string>) => {
      state.toasts = state.toasts.filter((toast) => toast.id !== action.payload);
    },
  },
});

export const { addToast, removeToast } = toastsSlice.actions;

export const selectToasts = (state: RootState) => state.toasts.toasts;

import { AiOutlineClose } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";

import { ConDesignItemLite } from "../../types/ConDesignItem";
import { getLoginRedirectUri } from "../../utils/redirectUriUtils";
import { suggestionCDIs } from "../../assets/CDIs/suggestionItems";
import { randomIntFromIncludedInterval } from "../../utils/randomNumberUtils";

interface Props {
  item: ConDesignItemLite | null;
  handleCloseModal: () => void;
}

const SigninModal = ({ item, handleCloseModal }: Props) => {
  const location = useLocation();

  const pictureUrl = item === null
    ? suggestionCDIs[randomIntFromIncludedInterval(0, suggestionCDIs.length - 1)].picture
    : item.pictureUrl

  const redirectUri = item === null 
    ? getLoginRedirectUri(location.pathname, '')
    : getLoginRedirectUri(location.pathname, `/item/${item.id}`);

  const signUpAnalyticsEvent = () => {
    window.dataLayer.push({
      "event": "cl_pop_up_signup_click",
    });
  }

  const signInAnalyticsEvent = () => {
    window.dataLayer.push({
      "event": "cl_pop_up_signin_click",
    });
  }

  return (
    <div className="modal">
      <div className="modal_background" onClick={handleCloseModal}></div>
      <div className="modal_wrapper">
        <div className="close-icon_row">
          <div className="close-icon">
            <AiOutlineClose onClick={handleCloseModal} size={25} />
          </div>
        </div>

        <div className="modal_wrapper_content">
          <div className="modal_title">
            <FormattedMessage
              id="UnlockAccountBenefits"
              defaultMessage="Unlock all the benefits with IDEA StatiCa account"
            />
          </div>
          <div className="modal_image">
            <img src={pictureUrl} alt="design item" />
          </div>
          <div className="modal_content_container">
            <p className="modal_text">
              <span className="red_dash">
                <code>&#8212;</code><code>&#8212;</code>
                &nbsp;&nbsp;  
              </span>
              <FormattedMessage
                id="AccessAllConnectionExamples"
                defaultMessage="Access all connection examples in the database"
              />
            </p>
            <p className="modal_text">
              <span className="red_dash">
                <code>&#8212;</code><code>&#8212;</code>
                &nbsp;&nbsp;              
              </span>
              <FormattedMessage
                id="DetailedViewOfConnection"
                defaultMessage="Detailed view of the selected connection"
              />
            </p>
            <p className="modal_text">
              <span className="red_dash">
                <code>&#8212;</code><code>&#8212;</code>
                &nbsp;&nbsp;
              </span>
              <FormattedMessage
                id="AbilityToProposeImprovements"
                defaultMessage="Ability to propose product improvements"
              />
            </p>
            <p className="modal_text">
              <span className="red_dash">
                <code>&#8212;</code><code>&#8212;</code>
                &nbsp;&nbsp;
              </span>
              <FormattedMessage
                id="AccessAllCloudApps"
                defaultMessage="Access to all cloud apps of IDEA StatiCa"
              />
            </p>
            <a
              className="signup_link"
              href="https://www.ideastatica.com/create-account"
              target="_blank"
              rel="noreferrer"
              onClick={signUpAnalyticsEvent}
            >
              <FormattedMessage id="CreateFreeAccount" defaultMessage="Create FREE account" />
            </a>
            <p className="signin_text">
              <FormattedMessage
                id="ModalNotAuthenticated_already_have_account"
                defaultMessage="Already have an account?"
              />{" "}
              <a href={redirectUri} className="signin_text_link" onClick={signInAnalyticsEvent}>
                <FormattedMessage id="SignIn" defaultMessage="Sign in" />
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SigninModal;
